@import url(https://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,700,700i,900,900i&display=swap&subset=latin-ext);
@import url(https://fonts.googleapis.com/earlyaccess/notosanskr.css);
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Lato, "Noto Sans KR", source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.fft-chart-container {
    position: relative;
    /* max-width: 1280px; */
    width: 100%;
    height: 700px;
    overflow: hidden;
}

.fft-chart-container canvas {
    width: 100%;
    height: 100%;
    background: transparent;
}

.fft-chart-container .labels {
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    color: white;
}

.fft-chart-container .labels div {
    white-space: nowrap;
    position: absolute;
    left: 0;
    top: 0;
    color: #000;
    font-size: 14px;
}

.fft-chart-container .labels .mv-tooltip {
    white-space: nowrap;
    position: absolute;
    left: 0;
    top: 0;
    color: #fff;
    font-size: 22px;
    background-color: #000;
    /* padding:10px; */
    /* border-radius: 10px; */
    opacity: 0.8;
}

.fft-chart-container .arrows {
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    color: white;
}

.fft-chart-container .arrows div {
    white-space: nowrap;
    position: absolute;
    left: 0;
    top: 0;
    color: #000;
    font-size: 14px;
}

.fft-chart-container span {
    position: absolute;
    right: 0;
    bottom: 0;
}



/* .heatmap-grid  div { padding:0px !important } */
.radar {
    /*
    background: -webkit-radial-gradient(center, rgba(32, 255, 77, 0.3) 0%, rgba(32, 255, 77, 0) 75%), 
                -webkit-repeating-radial-gradient(rgba(32, 255, 77, 0) 5.8%, rgba(32, 255, 77, 0) 18%, rgba(32, 255, 77, 1) 18.6%, rgba(32, 255, 77, 0) 18.9%), 
                -webkit-linear-gradient(90deg, rgba(32, 255, 77, 0) 49.5%, rgba(32, 255, 77, 1) 50%, rgba(32, 255, 77, 1) 50%, rgba(32, 255, 77, 0) 50.2%), 
                -webkit-linear-gradient(0deg, rgba(32, 255, 77, 0) 49.5%, rgba(32, 255, 77, 1) 50%, rgba(32, 255, 77, 1) 50%, rgba(32, 255, 77, 0) 50.2%);
     */
    /*background: radial-gradient(center, rgba(32, 255, 77, 0.3) 0%, rgba(32, 255, 77, 0) 75%), repeating-radial-gradient(rgba(32, 255, 77, 0) 5.8%, rgba(32, 255, 77, 0) 18%, rgba(32, 255, 77, 1) 18.6%, rgba(32, 255, 77, 0) 18.9%), linear-gradient(90deg, rgba(32, 255, 77, 0) 49.5%, rgba(32, 255, 77, 1) 50%, rgba(32, 255, 77, 1) 50%, rgba(32, 255, 77, 0) 50.2%), linear-gradient(0deg, rgba(32, 255, 77, 0) 49.5%, rgba(32, 255, 77, 1) 50%, rgba(32, 255, 77, 1) 50%, rgba(32, 255, 77, 0) 50.2%);*/
    width: 100%;
    height: 100%;
    /*max-height: 75vh;*/
    /*max-width: 75vh;*/
    position: relative;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    /*border-radius: 50%;*/
    /*border: 0.2rem solid #20ff4d;*/
    overflow: hidden;
}
/*.radar:before {*/
/*    content: ' ';*/
/*    display: block;*/
/*    position: absolute;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    border-radius: 50%;*/
/*    !*animation: blips 5s infinite;*!*/
/*    animation-timing-function: linear;*/
/*    animation-delay: 1.4s;*/
/*}*/
.radar-beam {
    content: ' ';
    display: block;
    /*background-image: linear-gradient(0deg, rgba(0, 255, 51, 0) 0%, rgba(0, 255, 51, 1) 100%);*/
    /*background: rgba(0, 255, 51, 1);*/
    background: rgba(255, 0, 0, 1);
    width: 8px;
    height: calc(100% * 0.73);
    position: absolute;
    bottom: 0;
    left: calc(50% - 4px);
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    -webkit-transform-origin: bottom right;
            transform-origin: bottom right;
    transition: all 0.5s ease-in-out;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
 

::-webkit-scrollbar { width: 0;}
::-webkit-scrollbar-track {  /*background: #f1f1f1;*/ }
::-webkit-scrollbar-thumb { background: #888; }
::-webkit-scrollbar-thumb:hover { background: #555; }
* {-ms-user-select: none; -webkit-user-select: none; user-select:none;}
*:focus {outline:none;}

@page {
    size: A4;
    margin: 0mm;
}

/* layout 공통 */
#root { height: 100%;}
#wrap { position:relative; height: 100%}
.MuiContainer-root { height: 100% }
.ratio-16-9 { aspect-ratio: 16 / 6; }
.text-left { text-align: left !important;}
.text-right { text-align: right !important;}
.text-white { color:white !important;}
.p-l-100 { padding-left: 100px}

#header { position:fixed; top:0; left:0; right:0;height:80px; border-bottom:1px solid rgba(255,255,255,0.2); z-index:11;  min-width:1280px;background:#fff;}
#header h1 { position:absolute; top:16px; left:30px; z-index:12;}
#header h1 a { display:block; width:180px; height:42px; text-indent:-9999px; background-size:100%;}
#header nav {display:block;}

#gnb { display:table;position:relative; margin:0 auto;}
#gnb>li {display:table-cell; padding:0 30px;text-align:center;letter-spacing:-0.5px;}
#gnb>li>a { display:block; line-height:80px; color:#222; font-weight:500;font-size:18px;}
#gnb>li:hover>a {color:#0055b8;}
/*#header .name {position:absolute; top:29px; right:30px;font-size:16px;}*/
#header .name {position:absolute; top:15px; right:30px;font-size:18px; font-weight: 500}

#cont-wrap {position:relative;margin-top:80px;min-width:1280px}

#footer {position:relative;background:#333;min-height:100px;}
#footer div {position:relative;width:1280px;margin:0 auto;}
#footer div p {color:#ddd;font-size:16px;padding-top:30px;}
#footer div .ct {position:absolute;right:0;top:30px;font-size:0;}
#footer div .ct li {position:relative; font-size:16px;display:inline-block;color:#ddd;}
#footer div .ct li + li {margin-left:20px;}
#footer div .ct li span {display:inline-block;padding-left:10px;}
#footer div .ct li span:before {content:'*';position:absolute;top:5px;left:0;}


#cont-wrap.app {min-width: auto; margin-top: 0}
#cont-wrap.app .sv { height: 120px }
#cont-wrap.app i { display: none }
#cont-wrap.app .sv div h3 { padding-top: 20px;  }
#cont-wrap.app .cont { width: 95%; margin: 2.5% auto }
#cont-wrap.app .ti-wrap { padding-left: 130px}
.app-back-profile button:hover { background-color: rgba(0,0,0,0.3)}
/*main :: visual */
.mv {position:relative;height:600px;overflow:hidden;background-position:50% 0; background-size:cover;background-repeat:no-repeat;}
/*.mv.bg01 {background-image:url(/user/assets/images/dashboard/mv_calm.jpg) ;} !*안정*!*/
/*.mv.bg02 {background-image:url(/user/assets/images/dashboard/mv_attention.jpg) ;} !*주의*!*/
/*.mv.bg03 {background-image:url(/user/assets/images/dashboard/mv_concentrate.jpg) ;} !*집중*!*/
/*.mv.bg04 {background-image:url(/user/assets/images/dashboard/mv_active.jpg) ;} !*활성*!*/
/*.mv.default {height:900px;background-image:url(/user/assets/images/dashboard/mv_default.jpg) ;}*/

.mv .innerbox {position:relative;width:1280px;margin:0 auto;height:100%;}
.mv .innerbox .date {position:absolute;left:0;bottom:70px;}
.mv .innerbox .date span {position:relative;display:block;color:#fff;font-size:30px;letter-spacing:-2px;line-height:1;padding-left:34px;}
.mv .innerbox .date span::before {position:absolute;left:0;top:2px;content:'';width:29px;height:28px;}
.mv .innerbox .date h3 {display:block;font-size:30px;font-weight:600;letter-spacing:-0.3px;line-height:1.8;color:#fff;border-bottom:3px solid rgba(255,255,255,0.8); }

.mv .innerbox .condition {position:absolute;right:0;top:0;width:640px;height:600px;}
.mv.bg01 .innerbox .condition {background: rgba(35,176,239,0.6);}
.mv.bg02 .innerbox .condition {background: rgba(108,175,111,0.6);}
.mv.bg03 .innerbox .condition {background: rgba(245,121,112,0.6);}
.mv.bg04 .innerbox .condition {background: rgba(188,122,205,0.6);}
.mv.default .innerbox .condition {position:absolute;left:0;top:280px;text-align:center;width:100%;}

.mv .innerbox .condition .inner {padding:110px 80px 0;}
.mv .innerbox .condition .inner h3 {display:block;position:relative;padding-left:96px;font-size:60px;color:#fff;font-weight:600;letter-spacing:-2px;line-height:1.5;}
.mv .innerbox .condition .inner h5 {display:block;position:relative;padding-left:96px;font-size:40px;color:#fff;font-weight:600;letter-spacing:-2px;line-height:1.5;}
.mv .innerbox .condition .inner h3::before {position:absolute;left:0;top:10px;content:'';width:85px;height:75px;}
.mv .innerbox .condition .inner p {font-weight:500;color:#fff;font-size:30px;letter-spacing:-2px;line-height:1.5;}
.mv .innerbox .condition .inner span {display:block;color:#fff;font-size:20px;letter-spacing:-0.5px;line-height:1.5;word-break: keep-all;}
/*.mv.bg01 .innerbox .condition .inner h3::before {background:url(/user/assets/images/dashboard/ico_calm.png);}*/
/*.mv.bg02 .innerbox .condition .inner h3::before {background:url(/user/assets/images/dashboard/ico_attention.png);}*/
/*.mv.bg03 .innerbox .condition .inner h3::before {background:url(/user/assets/images/dashboard/ico_concentrate.png);}*/
/*.mv.bg04 .innerbox .condition .inner h3::before {background:url(/user/assets/images/dashboard/ico_active.png);}*/

.mv .innerbox .condition .bt-start {display:block;position:absolute;bottom:70px;left:80px;border:3px solid rgba(255,255,255,0.8);padding:0 30px;border-radius:30px; cursor: pointer;transition-duration: 0.3s}
.mv .innerbox .condition .bt-start span {position:relative;display:inline-block;line-height:60px;color:#fff;font-size:20px;font-weight:500;padding-right:40px;}
.mv .innerbox .condition .bt-start span::after {position:absolute;right:0;top:22px;content:'';width:11px;height:17px;}
.mv .innerbox .condition .bt-start.ex-complete { border:0; padding-right: 0; } 

.mv.bg01 .innerbox .bt-start:hover {background: rgba(35,176,239,0.6);}
.mv.bg02 .innerbox .bt-start:hover {background: rgba(108,175,111,0.6);}
.mv.bg03 .innerbox .bt-start:hover {background: rgba(245,121,112,0.6);}
.mv.bg04 .innerbox .bt-start:hover {background: rgba(188,122,205,0.6);}

.mv.bg01 .innerbox .bt-start.ex-complete {background: rgba(35,176,239,0.6);}
.mv.bg02 .innerbox .bt-start.ex-complete {background: rgba(108,175,111,0.6);}
.mv.bg03 .innerbox .bt-start.ex-complete {background: rgba(245,121,112,0.6);}
.mv.bg04 .innerbox .bt-start.ex-complete {background: rgba(188,122,205,0.6);}


.mv.default .innerbox .condition .bt-start {bottom:320px;left:50%;margin-left:-105px;}


/*main :: 처방운동 */
.sec-presc {position:relative;background:#f8f8f8;box-sizing:border-box;}
.sec-presc .innerbox {position:relative;width:1280px;margin:0 auto;padding:80px 0;}
.sec-presc .innerbox h4 {display:block;position:relative;font-size:50px;font-weight:600;letter-spacing:-2px;text-align:center;line-height:120px;z-index:1;}
.sec-presc .innerbox h4 i {display:block;width:100%;text-align:center;position:absolute;top:-36px;left:50%;margin-left:-50%;font-size:72px;font-weight:600;color:#e0e5ef;z-index:-1;}
.sec-presc .innerbox .bt-more {position:absolute;right:0;top:125px;display:block;z-index:2;}
.sec-presc .innerbox .bt-more span {font-size:20px;color:#333;font-weight:400;padding-right:30px;line-height:1.2;}
.sec-presc .innerbox .bt-more span::after {position:absolute;right:0;top:2px;width:20px;height:20px;border:1px solid #333;border-radius:50%;content:'+';line-height:20px;text-align:center;font-size:22px;}
.sec-presc .innerbox ul {position:relative;font-size:0;margin:0 -40px;}
.sec-presc .innerbox ul li {position:relative;display:inline-block;font-size:20px;width:600px;border-bottom:1px solid #ddd;margin:0 40px;vertical-align:top;}
.sec-presc .innerbox ul li.empty { border:0px; text-align: center; width: 1280px;color: #999}
.sec-presc .innerbox ul li.empty h4 { font-size: 28px; margin:0; height: 90px;color: #999}
.sec-presc .innerbox ul li.empty img { width : 80px; margin-top: 50px}
.sec-presc .innerbox ul li dl {display:block;clear:both;padding:20px 0;}
.sec-presc .innerbox ul li dl dt {position:absolute;left:0;top:20px;width:90px;height:90px;border:1px solid #dddd;border-radius:50%;overflow:hidden;z-index:1;}
.sec-presc .innerbox ul li dl dt img {width:100%;}
.sec-presc .innerbox ul li dl dd {display:block;padding-left:110px;height:120px;font-size:18px;color:#666;line-height:1.3;word-break:keep-all;overflow:hidden;}
.sec-presc .innerbox ul li dl dd p {font-weight:500;font-size:24px;line-height:2;color:#333;}
.sec-presc .innerbox ul li dl dd p span {display:inline-block;background:#014fb4;border-radius:5px;color:#fff;font-size:18px;line-height:1.2;padding:5px 8px;margin-left:10px;}
.sec-presc .innerbox ul li .check {position:absolute;left:0;top:20px;width:90px;height:90px;border-radius:50%;z-index:2;background:rgb(255 235 59 / 37%);}
.sec-presc .innerbox ul li .check span {display:block;position:absolute;left:15px;top:2px;width:86px;height:71px;text-indent:-999999px;}
.sec-presc .innerbox ul li .log {padding-left:110px; margin-top: 10px; font-size:16px; font-weight: 500; color:#014fb4}

/*main :: 처방운동 */
.sec-presc .auto.innerbox {position:relative;margin:0 auto; padding:0; width: 100%}
.sec-presc .auto.innerbox ul {position:relative;font-size:0;margin:0;}
.sec-presc .auto.innerbox ul li {position:relative;display:inline-block;font-size:1rem;width:50%;border-bottom:1px solid #ddd;margin:0;vertical-align:top;}
.sec-presc .auto.innerbox ul li dl dt {position:absolute;left:20px;top:20px;width:64px;height:64px;border:1px solid #dddd;border-radius:50%;overflow:hidden;z-index:1;}
.sec-presc .auto.innerbox ul li dl dd {display:block;padding-left:110px;height:85px;font-size:18px;color:#666;line-height:1.3;word-break:keep-all;overflow:hidden;}
.sec-presc .auto.innerbox ul li dl dd p {font-weight:500;font-size:1rem;line-height:2;color:#333;}
.sec-presc .auto.innerbox ul li dl dd p span {display:inline-block;background:#014fb4;border-radius:5px;color:#fff;font-size:18px;line-height:1.2;padding:5px 8px;margin-left:10px;}
.sec-presc .auto.innerbox ul li .check {position:absolute;left:0;top:20px;width:64px;height:64px;border-radius:50%;z-index:2;background:rgb(255 235 59 / 37%);}


/*main :: 뇌기능측정 */
.sec-graph {position:relative;background:center 0/cover no-repeat;box-sizing:border-box;min-height:680px;}
.sec-graph .innerbox {position:relative;width:1280px;margin:0 auto;padding:80px 0;}
.sec-graph .innerbox h4 {display:block;position:relative;font-size:50px;font-weight:600;letter-spacing:-2px;text-align:center;line-height:120px;z-index:1;color:#fff;}
.sec-graph .innerbox h4 i {display:block;width:100%;text-align:center;position:absolute;top:-36px;left:50%;margin-left:-50%;font-size:72px;font-weight:600;color:#4871bb;z-index:-1;}
.sec-graph .innerbox .bt-more {position:absolute;right:0;top:125px;display:block;z-index:2;}
.sec-graph .innerbox .bt-more span {font-size:20px;color:#fff;font-weight:400;padding-right:30px;line-height:1.2;}
.sec-graph .innerbox .bt-more span::after {position:absolute;right:0;top:2px;width:20px;height:20px;border:1px solid #fff;border-radius:50%;content:'+';line-height:20px;text-align:center;font-size:22px;}
.sec-graph .innerbox .graph-box {position:relative;width:1000px;margin:0 auto;text-align:center;}
.sec-graph .innerbox .graph-box .gr-tit {position:relative;text-align:left;padding-bottom:30px;}
.sec-graph .innerbox .graph-box .gr-tit p {font-weight:500;font-size:26px;color:#fff;}
.sec-graph .innerbox .graph-box .gr-tit ul {position:absolute;right:0;top:0;display:block;font-size:0;}
.sec-graph .innerbox .graph-box .gr-tit ul li {position:relative;display:inline-block;font-size:18px;line-height:1;color:#fff;}
.sec-graph .innerbox .graph-box .gr-tit ul li + li {margin-left:30px;}
.sec-graph .innerbox .graph-box .gr-tit ul li span {display:block;padding-left:30px;}
.sec-graph .innerbox .graph-box .gr-tit ul li span::before {position:absolute;left:0;top:0;width:17px;height:17px;content:'';}
.sec-graph .innerbox .graph-box .gr-tit ul li.now span::before {border:2px solid #00b0f5;background:rgb(0 176 245 / 50%);}
.sec-graph .innerbox .graph-box .gr-tit ul li.pre span::before {border:2px solid #d599df;background:rgb(213 153 223 / 50%);}

/*main :: 뇌의식패턴 & 자각증상 */
.sec-bpa {position:relative;box-sizing:border-box;min-height:540px;color:#222;}
.sec-bpa .innerbox {position:relative;width:1280px;margin:80px auto;}
.sec-bpa .innerbox h4 {display:block;font-size:36px;font-weight:600;letter-spacing:-2px;line-height:1;padding-bottom:40px;}
.sec-bpa .innerbox .l-pattern {position:absolute;left:0;top:0;width:560px;}
.sec-bpa .innerbox .l-pattern .pattern {position:relative;}
.sec-bpa .innerbox .r-awakening {position:absolute;right:0;top:0;width:640px;}
.sec-bpa .innerbox .r-awakening .bt-more {position:absolute;right:0;top:5px;display:block;z-index:2;}
.sec-bpa .innerbox .r-awakening .bt-more span {font-size:20px;color:#333;font-weight:400;padding-right:30px;line-height:1.2;}
.sec-bpa .innerbox .r-awakening .bt-more span::after {position:absolute;right:0;top:2px;width:20px;height:20px;border:1px solid #333;border-radius:50%;content:'+';line-height:20px;text-align:center;font-size:22px;}
.sec-bpa .innerbox .r-awakening .awakening {position:relative;font-size:24px;}


/* sub layout*/
.sv {position:relative;height:160px;background:#0156b8;overflow:hidden;}
.sv.white {background-color: white;}
.sv.white h3 {color: #333}
.sv.green { background-color: #247C40 !important;}
.sv div {position:relative;width:1280px;margin:0 auto;height:160px;}
.sv div h3 {display:block;padding:40px 0 0;font-size:30px;font-weight:600;letter-spacing:-2px;line-height:1.5;color:#fff;}
.sv div span {display:block;font-size:20px;letter-spacing:-1px;color:rgba(255,255,255,0.8);}
.sv div i{position:absolute;display:block;right:0;bottom:-10px;width:640px;text-align:right;font-size:72px;line-height:1;letter-spacing:-2px;font-weight:600;color:rgba(255,255,255,0.1);}
.cont {position:relative;width:1280px; margin:60px auto;min-height:500px;}



/* common pop */
.common-dialog-title .MuiTypography-root { font-family: 'Noto Sans KR'; font-weight: bold; color: #0060C0; font-size: 36px; letter-spacing: -2px}
.common-dialog-content { font-family: 'Noto Sans KR'; min-width: 550px; text-align: center; font-size: 21px; margin: 40px 0 }
.common-dialog-content audio { width: 80% }
.common-dialog-action.MuiDialogActions-root { background-color: #0060C0; color: #fff; min-height: 80px}
.common-dialog-action .MuiButton-label { color: #fff; font-size: 26px; font-weight: bold }
.common-dialog-close { margin-top: 10px}
.common-dialog-close .MuiSvgIcon-root { font-size: 35px}

.common-top-dialog-title .MuiTypography-root { font-family: 'Noto Sans KR'; letter-spacing: -2px; color: #0060C0;}
.common-top-dialog-content { font-family: 'Noto Sans KR'; min-width: 550px; text-align: left; font-size: 21px; padding: 40px 20px !important; }
.common-top-dialog-submit.MuiButton-containedPrimary { background-color: #0156B8}
.common-top-dialog-submit.MuiButton-containedPrimary:hover { background-color: #063387 }

/* common material button */
.common-sm-button { border: 1px solid #BEBEBE }
.common-button {border: 1px solid #BEBEBE !important; padding: 5px 30px !important; border-radius: 20px !important; color: #666 !important; background-color: #fff}
.common-button .MuiButton-endIcon { margin-right: -15px !important;}
.common-button .rightIcon {color: #00DD94; font-size: 25px !important; font-weight: bold !important;}

.common-button.blue { background-color: #0156b8; border: 2px solid #0156b8 !important;}
.common-button.blue:hover {background-color: #073A8F; }
.common-button.blue .MuiButton-label { color: #fff; }

/* monitor */
.monitor-dialog-content { font-family: 'Noto Sans KR'; min-width: 1024px; }

/* measure */


.sc-measure {position:relative;max-width: 800px; margin: 0 auto }
.sc-measure ul {position:relative;font-size:0;}
.sc-measure ul li {border-bottom:0.1px solid #dcdcdc;padding-bottom:3rem;margin-bottom:3rem;}
.sc-measure ul li:last-child {margin-bottom:0;}
.sc-measure ul li dl dt {font-size:1.5rem;line-height:1.5;font-weight:500;letter-spacing:-0.1rem;padding-bottom:1rem;}
.sc-measure ul li dl dd ul {text-align:center;}

.sc-measure ul li dl dd ul li {width:calc(10% - 0.2rem);margin:0 0.2rem 0 0;display:inline-block;font-size:1rem;color:#333;font-weight:400;line-height:1.8;border-bottom:none;padding:0;}
.sc-measure ul li dl dd ul li:first-child {margin-left:0;}
.sc-measure ul li dl dd ul li div {width:3.6rem;height:3.6rem;border-radius:50%;margin:0 auto; cursor: pointer}
.sc-measure ul li dl dd ul li:nth-child(1) div {border:1px solid #ccddf1;background:#e5eef8; transition-duration: 0.3s}
.sc-measure ul li dl dd ul li:nth-child(1) div:hover {border:1px solid #e5eef8;background:#e5eef8; transition-duration: 0.3s}
.sc-measure ul li dl dd ul li:nth-child(2) div {border:1px solid #ccddf1;background:#ccddf1; transition-duration: 0.3s}
.sc-measure ul li dl dd ul li:nth-child(2) div:hover  {border:1px solid #ccddf1;background:#ccddf1; transition-duration: 0.3s}
.sc-measure ul li dl dd ul li:nth-child(3) div {border:1px solid #b2ccea;background:#b2ccea; transition-duration: 0.3s}
.sc-measure ul li dl dd ul li:nth-child(3) div:hover  {border:1px solid #b2ccea;background:#b2ccea; transition-duration: 0.3s}
.sc-measure ul li dl dd ul li:nth-child(4) div {border:1px solid #99bbe3;background:#99bbe3; transition-duration: 0.3s}
.sc-measure ul li dl dd ul li:nth-child(4) div:hover  {border:1px solid #99bbe3;background:#99bbe3; transition-duration: 0.3s}
.sc-measure ul li dl dd ul li:nth-child(5) div {border:1px solid #80aadb;background:#80aadb; transition-duration: 0.3s}
.sc-measure ul li dl dd ul li:nth-child(5) div:hover  {border:1px solid #80aadb;background:#80aadb; transition-duration: 0.3s}
.sc-measure ul li dl dd ul li:nth-child(6) div {border:1px solid #679ad4;background:#679ad4; transition-duration: 0.3s}
.sc-measure ul li dl dd ul li:nth-child(6) div:hover  {border:1px solid #679ad4;background:#679ad4; transition-duration: 0.3s}
.sc-measure ul li dl dd ul li:nth-child(7) div {border:1px solid #4d88cd;background:#4d88cd; transition-duration: 0.3s}
.sc-measure ul li dl dd ul li:nth-child(7) div:hover  {border:1px solid #4d88cd;background:#4d88cd; transition-duration: 0.3s}
.sc-measure ul li dl dd ul li:nth-child(8) div {border:1px solid #3478c6;background:#3478c6; transition-duration: 0.3s}
.sc-measure ul li dl dd ul li:nth-child(8) div:hover  {border:1px solid #3478c6;background:#3478c6; transition-duration: 0.3s}
.sc-measure ul li dl dd ul li:nth-child(9) div {border:1px solid #1a67bf;background:#1a67bf; transition-duration: 0.3s}
.sc-measure ul li dl dd ul li:nth-child(9) div:hover  {border:1px solid #1a67bf;background:#1a67bf; transition-duration: 0.3s}
.sc-measure ul li dl dd ul li:nth-child(10) div {border:1px solid #0156b8;background:#0156b8; transition-duration: 0.3s}
.sc-measure ul li dl dd ul li:nth-child(10) div:hover  {border:1px solid #0156b8;background:#0156b8; transition-duration: 0.3s}

.sc-measure.life-cycle ul li dl dd ul li div {width:3.6rem;height:3.6rem;border-radius:10px;margin:0 auto; cursor: pointer}
.sc-measure.life-cycle ul li dl dd ul li {width:calc(9% - 0.2rem);margin:0 0.2rem 0 0;display:inline-block;font-size:1rem;color:#8e8786;font-weight:400;line-height:1.8;border-bottom:none;padding:0;}
.sc-measure.life-cycle ul li dl dd ul li:nth-child(6) div {border:1px solid #ccddf1;background:#fff; transition-duration: 0.3s}

.sc-measure.life-cycle ul li dl dd ul li:nth-child(1) div {border:1px solid #0156b8;background:#0156b8; transition-duration: 0.3s}
.sc-measure.life-cycle ul li dl dd ul li:nth-child(1) div:hover  {border:1px solid #0156b8;background:#0156b8; transition-duration: 0.3s}
.sc-measure.life-cycle ul li dl dd ul li:nth-child(2) div {border:1px solid #3478c6;background:#3478c6; transition-duration: 0.3s}
.sc-measure.life-cycle ul li dl dd ul li:nth-child(2) div:hover  {border:1px solid #3478c6;background:#3478c6; transition-duration: 0.3s}
.sc-measure.life-cycle ul li dl dd ul li:nth-child(3) div {border:1px solid #679ad4;background:#679ad4; transition-duration: 0.3s}
.sc-measure.life-cycle ul li dl dd ul li:nth-child(3) div:hover  {border:1px solid #679ad4;background:#679ad4; transition-duration: 0.3s}
.sc-measure.life-cycle ul li dl dd ul li:nth-child(4) div {border:1px solid #99bbe3;background:#99bbe3; transition-duration: 0.3s}
.sc-measure.life-cycle ul li dl dd ul li:nth-child(4) div:hover  {border:1px solid #99bbe3;background:#99bbe3; transition-duration: 0.3s}
.sc-measure.life-cycle ul li dl dd ul li:nth-child(5) div {border:1px solid #ccddf1;background:#ccddf1; transition-duration: 0.3s}
.sc-measure.life-cycle ul li dl dd ul li:nth-child(5) div:hover  {border:1px solid #ccddf1;background:#ccddf1; transition-duration: 0.3s}

.sc-measure.life-cycle ul li dl dd ul li:nth-child(11) div {border:1px solid #0156b8;background:#0156b8; transition-duration: 0.3s}
.sc-measure.life-cycle ul li dl dd ul li:nth-child(11) div:hover  {border:1px solid #0156b8;background:#0156b8; transition-duration: 0.3s}
.sc-measure.life-cycle ul li dl dd ul li:nth-child(10) div {border:1px solid #3478c6;background:#3478c6; transition-duration: 0.3s}
.sc-measure.life-cycle ul li dl dd ul li:nth-child(10) div:hover  {border:1px solid #3478c6;background:#3478c6; transition-duration: 0.3s}
.sc-measure.life-cycle ul li dl dd ul li:nth-child(9) div {border:1px solid #679ad4;background:#679ad4; transition-duration: 0.3s}
.sc-measure.life-cycle ul li dl dd ul li:nth-child(9) div:hover  {border:1px solid #679ad4;background:#679ad4; transition-duration: 0.3s}
.sc-measure.life-cycle ul li dl dd ul li:nth-child(8) div {border:1px solid #99bbe3;background:#99bbe3; transition-duration: 0.3s}
.sc-measure.life-cycle ul li dl dd ul li:nth-child(8) div:hover  {border:1px solid #99bbe3;background:#99bbe3; transition-duration: 0.3s}
.sc-measure.life-cycle ul li dl dd ul li:nth-child(7) div {border:1px solid #ccddf1;background:#ccddf1; transition-duration: 0.3s}
.sc-measure.life-cycle ul li dl dd ul li:nth-child(7) div:hover  {border:1px solid #ccddf1;background:#ccddf1; transition-duration: 0.3s}


/*.sc-measure.life ul li dl dd ul li div {width:3.6rem;height:3.6rem;border-radius:50%;margin:0 auto; cursor: pointer}*/
/*.sc-measure.life ul li dl dd ul li {width:calc(9% - 0.2rem);margin:0 0.2rem 0 0;display:inline-block;font-size:1rem;color:#8e8786;font-weight:400;line-height:1.8;border-bottom:none;padding:0;}*/
/*.sc-measure.life ul li dl dd ul li:nth-child(6) div {border:1px solid #ccddf1;background:#fff; transition-duration: 0.3s}*/

/*.sc-measure.life ul li dl dd ul li:nth-child(1) div {border:1px solid #0156b8;background:#0156b8; transition-duration: 0.3s}*/
/*.sc-measure.life ul li dl dd ul li:nth-child(1) div:hover  {border:1px solid #0156b8;background:#0156b8; transition-duration: 0.3s}*/
/*.sc-measure.life ul li dl dd ul li:nth-child(2) div {border:1px solid #3478c6;background:#3478c6; transition-duration: 0.3s}*/
/*.sc-measure.life ul li dl dd ul li:nth-child(2) div:hover  {border:1px solid #3478c6;background:#3478c6; transition-duration: 0.3s}*/
/*.sc-measure.life ul li dl dd ul li:nth-child(3) div {border:1px solid #679ad4;background:#679ad4; transition-duration: 0.3s}*/
/*.sc-measure.life ul li dl dd ul li:nth-child(3) div:hover  {border:1px solid #679ad4;background:#679ad4; transition-duration: 0.3s}*/
/*.sc-measure.life ul li dl dd ul li:nth-child(4) div {border:1px solid #99bbe3;background:#99bbe3; transition-duration: 0.3s}*/
/*.sc-measure.life ul li dl dd ul li:nth-child(4) div:hover  {border:1px solid #99bbe3;background:#99bbe3; transition-duration: 0.3s}*/
/*.sc-measure.life ul li dl dd ul li:nth-child(5) div {border:1px solid #ccddf1;background:#ccddf1; transition-duration: 0.3s}*/
/*.sc-measure.life ul li dl dd ul li:nth-child(5) div:hover  {border:1px solid #ccddf1;background:#ccddf1; transition-duration: 0.3s}*/

/*.sc-measure.life ul li dl dd ul li:nth-child(11) div {border:1px solid #0156b8;background:#0156b8; transition-duration: 0.3s}*/
/*.sc-measure.life ul li dl dd ul li:nth-child(11) div:hover  {border:1px solid #0156b8;background:#0156b8; transition-duration: 0.3s}*/
/*.sc-measure.life ul li dl dd ul li:nth-child(10) div {border:1px solid #3478c6;background:#3478c6; transition-duration: 0.3s}*/
/*.sc-measure.life ul li dl dd ul li:nth-child(10) div:hover  {border:1px solid #3478c6;background:#3478c6; transition-duration: 0.3s}*/
/*.sc-measure.life ul li dl dd ul li:nth-child(9) div {border:1px solid #679ad4;background:#679ad4; transition-duration: 0.3s}*/
/*.sc-measure.life ul li dl dd ul li:nth-child(9) div:hover  {border:1px solid #679ad4;background:#679ad4; transition-duration: 0.3s}*/
/*.sc-measure.life ul li dl dd ul li:nth-child(8) div {border:1px solid #99bbe3;background:#99bbe3; transition-duration: 0.3s}*/
/*.sc-measure.life ul li dl dd ul li:nth-child(8) div:hover  {border:1px solid #99bbe3;background:#99bbe3; transition-duration: 0.3s}*/
/*.sc-measure.life ul li dl dd ul li:nth-child(7) div {border:1px solid #ccddf1;background:#ccddf1; transition-duration: 0.3s}*/
/*.sc-measure.life ul li dl dd ul li:nth-child(7) div:hover  {border:1px solid #ccddf1;background:#ccddf1; transition-duration: 0.3s}*/


.sc-measure.life ul li dl dd ul li:nth-child(10) div {border:1px solid #ccddf1;background:#e5eef8; transition-duration: 0.3s}
.sc-measure.life ul li dl dd ul li:nth-child(10) div:hover {border:1px solid #e5eef8;background:#e5eef8; transition-duration: 0.3s}
.sc-measure.life ul li dl dd ul li:nth-child(9) div {border:1px solid #ccddf1;background:#ccddf1; transition-duration: 0.3s}
.sc-measure.life ul li dl dd ul li:nth-child(9) div:hover  {border:1px solid #ccddf1;background:#ccddf1; transition-duration: 0.3s}
.sc-measure.life ul li dl dd ul li:nth-child(8) div {border:1px solid #b2ccea;background:#b2ccea; transition-duration: 0.3s}
.sc-measure.life ul li dl dd ul li:nth-child(8) div:hover  {border:1px solid #b2ccea;background:#b2ccea; transition-duration: 0.3s}
.sc-measure.life ul li dl dd ul li:nth-child(7) div {border:1px solid #99bbe3;background:#99bbe3; transition-duration: 0.3s}
.sc-measure.life ul li dl dd ul li:nth-child(7) div:hover  {border:1px solid #99bbe3;background:#99bbe3; transition-duration: 0.3s}
.sc-measure.life ul li dl dd ul li:nth-child(6) div {border:1px solid #80aadb;background:#80aadb; transition-duration: 0.3s}
.sc-measure.life ul li dl dd ul li:nth-child(6) div:hover  {border:1px solid #80aadb;background:#80aadb; transition-duration: 0.3s}
.sc-measure.life ul li dl dd ul li:nth-child(5) div {border:1px solid #679ad4;background:#679ad4; transition-duration: 0.3s}
.sc-measure.life ul li dl dd ul li:nth-child(5) div:hover  {border:1px solid #679ad4;background:#679ad4; transition-duration: 0.3s}
.sc-measure.life ul li dl dd ul li:nth-child(4) div {border:1px solid #4d88cd;background:#4d88cd; transition-duration: 0.3s}
.sc-measure.life ul li dl dd ul li:nth-child(4) div:hover  {border:1px solid #4d88cd;background:#4d88cd; transition-duration: 0.3s}
.sc-measure.life ul li dl dd ul li:nth-child(3) div {border:1px solid #3478c6;background:#3478c6; transition-duration: 0.3s}
.sc-measure.life ul li dl dd ul li:nth-child(3) div:hover  {border:1px solid #3478c6;background:#3478c6; transition-duration: 0.3s}
.sc-measure.life ul li dl dd ul li:nth-child(2) div {border:1px solid #1a67bf;background:#1a67bf; transition-duration: 0.3s}
.sc-measure.life ul li dl dd ul li:nth-child(2) div:hover  {border:1px solid #1a67bf;background:#1a67bf; transition-duration: 0.3s}
.sc-measure.life ul li dl dd ul li:nth-child(1) div {border:1px solid #0156b8;background:#0156b8; transition-duration: 0.3s}
.sc-measure.life ul li dl dd ul li:nth-child(1) div:hover  {border:1px solid #0156b8;background:#0156b8; transition-duration: 0.3s}


.sc-measure.recognition { max-width:  1024px}
.sc-measure.recognition dt { width: 60%; display: inline-block; position: relative; margin-right: 5%}
.sc-measure.recognition dt div { position: absolute; top: -50px}
.sc-measure.recognition dd { width: 35%; display: inline-block}
.sc-measure.recognition dd ul li { width: 30%;}
.sc-measure.recognition dd ul li div { background-color: #fff !important; border: 1px solid #0356B8 !important;}
.sc-measure.recognition dd ul li.checked div { background-color: #4D88CD !important;}

.sc-measure ul li dl dd ul li div.bgn { background-color: #fff !important;}

.level-dialog.width-810 {width: 810px !important;}
.level-dialog #responsive-dialog-title { border-bottom: 1px solid #EEEDEF; padding-bottom: 20px; border-top:5px solid #3F51B5}
.level-dialog #responsive-dialog-title.print { border-bottom:5px solid #3F51B5}
.level-dialog .print-bottom { border-top:5px solid #3F51B5; text-align: center; padding-top: 10px; font-weight: bold; font-size: 1.3rem}
.level-dialog #responsive-dialog-title h2 { color: #120075; text-align: center; font-size: 1.5rem;font-weight: bold;}
.level-dialog .title { font-size: 1.3rem; font-weight: bold; text-align: center; padding:20px 20px 10px 20px; min-height:76px;}
.level-dialog .title.point { text-align: left;}
.level-dialog .title.point:before { content:'■'; font-size: 1.7rem; padding-right: 6px;}
.level-dialog .box { background-color: #F6F9FC; padding:30px;}
.level-dialog .box li:before { content: '-'; padding-right: 4px; }
.level-dialog .box li { margin-bottom: 4px; font-weight: 400;  font-size: 0.9rem}
@media (max-width: 1344px) {
    .level-dialog .box li { font-size: 0.79rem}
}
.level-dialog .time-table { text-align: center}
.level-dialog .time-table .sub-title { font-size:0.9rem; font-weight: bold; border-bottom: 1px solid #ddd; padding-bottom: 10px; margin-bottom: 10px}
.level-dialog .time-table .MuiGrid-item:first-child  { border-right: 1px solid #EEEDEF}

.level-dialog .result-wrap { background-color: #F6F9FC }
.level-dialog .result-box { border: 1px solid #100070; text-align:center; display: flex; flex-direction: column; justify-content: center }
.level-dialog .result-box p { font-weight: bold ; font-size: 1.1rem; border-bottom: 1px solid #100070; padding: 10px 0 }
.level-dialog .result-box p.text-blue { color: #100070}
.level-dialog .result-box .score { border:0; font-weight: bold ; font-size: 1.3rem; padding: 20px 0; background-color: #EDF4FA; line-height: 30px; border-radius: 4px}

.level-dialog .flex { display: flex }
.level-dialog .inline { display: inline }
.level-dialog .inline { margin-top: 45px}
.level-dialog .left-box-title { -webkit-writing-mode: vertical-rl; -ms-writing-mode: tb-rl; writing-mode: vertical-rl; padding-right:12px; letter-spacing: 5px; background-color: #033E83; color: white;  border-radius: 4px;text-align: center;font-size: 1rem;font-weight: bold; width: 60px; margin-right: 20px; margin-left:30px; min-height: 256px}
.level-dialog .left-box-title-land {  background-color: #033E83; color: white;  border-radius: 4px; font-size: 1.1rem;font-weight: bold; width: 180px; margin-right: 20px; margin-left:30px; margin-bottom: 20px; text-align: left; padding: 10px; padding-left: 20px }
.level-dialog .left-box-title-land.mt45 { margin-top: 45px}
.level-dialog .result-each-box { max-width: 600px !important; padding-left: 30px}

.level-dialog table thead { border-bottom: 1px solid #000}
.level-dialog table th { text-align: center; font-weight: bold; color: #100070; background: #F6F9FC; border-top: 1px solid #100070; border-bottom: 1px solid #100070; font-size: 1.1rem; padding: 14px }
.level-dialog table td { text-align: center }

.level-dialog.develop,.level-dialog.train #responsive-dialog-title {border-top:5px solid #247C40 !important;}
.level-dialog.develop,.level-dialog.train #responsive-dialog-title h2 {color: #247C40 !important;}
.level-dialog.develop,.level-dialog.train .MuiButton-containedPrimary {background-color: #247C40 !important;}
.level-dialog.develop,.level-dialog.train .result-box .score { background-color: #F1FAF2 !important;}

/*.level-dialog.develop,.level-dialog.train .MuiButtonGroup-root button {border-color:#247C40 !important; color:  #247C40 !important;}*/
.level-dialog.develop,.level-dialog.train .left-box-title-land { background-color: #247C40 !important}
.level-dialog.develop,.level-dialog.train .left-box-title { background-color: #247C40 !important}

.level-dialog.develop.test-2-color #responsive-dialog-title {border-top:5px solid #25A2A4 !important;}
.level-dialog.develop.test-2-color #responsive-dialog-title h2 {color: #25A2A4 !important;}
.level-dialog.develop.test-2-color .MuiButton-containedPrimary {background-color: #25A2A4 !important;}
.level-dialog.develop.test-2-color .result-box .score { background-color: #EFFAFB !important;}
.level-dialog.develop.test-2-color .left-box-title-land { background-color: #25A2A4 !important}
.level-dialog.develop.test-2-color .left-box-title { background-color: #25A2A4 !important}

.level-dialog.develop.test-3-color #responsive-dialog-title {border-top:5px solid #035031 !important;}
.level-dialog.develop.test-3-color #responsive-dialog-title h2 {color: #035031 !important;}
.level-dialog.develop.test-3-color .MuiButton-containedPrimary {background-color: #035031 !important;}
.level-dialog.develop.test-3-color .result-box .score { background-color: #F1FBF8 !important;}
.level-dialog.develop.test-3-color .left-box-title-land { background-color: #035031 !important}
.level-dialog.develop.test-3-color .left-box-title { background-color: #035031 !important}

.level-dialog.develop.test-32-color #responsive-dialog-title {border-top:5px solid #E67398 !important;}
.level-dialog.develop.test-32-color #responsive-dialog-title h2 {color: #E67398 !important;}
.level-dialog.develop.test-32-color .MuiButton-containedPrimary {background-color: #E67398 !important;}
.level-dialog.develop.test-32-color .result-box .score { background-color: #FFF1F6 !important;}
.level-dialog.develop.test-32-color .left-box-title-land { background-color: #E67398 !important}
.level-dialog.develop.test-32-color .left-box-title { background-color: #E67398 !important}

.level-dialog.develop.test-33-color #responsive-dialog-title {border-top:5px solid #552342 !important;}
.level-dialog.develop.test-33-color #responsive-dialog-title h2 {color: #552342 !important;}
.level-dialog.develop.test-33-color .MuiButton-containedPrimary {background-color: #552342 !important;}
.level-dialog.develop.test-33-color .result-box .score { background-color: #FFF1F6 !important;}
.level-dialog.develop.test-33-color .left-box-title-land { background-color: #552342 !important}
.level-dialog.develop.test-33-color .left-box-title { background-color: #552342 !important}

.level-dialog.develop.test-4-color #responsive-dialog-title {border-top:5px solid #3E550C !important;}
.level-dialog.develop.test-4-color #responsive-dialog-title h2 {color: #3E550C !important;}
.level-dialog.develop.test-4-color .MuiButton-containedPrimary {background-color: #3E550C !important;}
.level-dialog.develop.test-4-color .result-box .score { background-color: #F1FAF2 !important;}
.level-dialog.develop.test-4-color .left-box-title-land { background-color: #3E550C !important}
.level-dialog.develop.test-4-color .left-box-title { background-color: #3E550C !important}

.level-dialog.develop.test-5-color #responsive-dialog-title {border-top:5px solid #257D41 !important;}
.level-dialog.develop.test-5-color #responsive-dialog-title h2 {color: #257D41 !important;}
.level-dialog.develop.test-5-color .MuiButton-containedPrimary {background-color: #257D41 !important;}
.level-dialog.develop.test-5-color .result-box .score { background-color: #F1FAF2 !important;}
.level-dialog.develop.test-6-color .left-box-title-land { background-color: #257D41 !important}
.level-dialog.develop.test-6-color .left-box-title { background-color: #257D41 !important}


.level-dialog.develop.test-6-color #responsive-dialog-title {border-top:5px solid #42279B !important;}
.level-dialog.develop.test-6-color #responsive-dialog-title h2 {color: #42279B !important;}
.level-dialog.develop.test-6-color .MuiButton-containedPrimary {background-color: #42279B !important;}
.level-dialog.develop.test-6-color .result-box .score { background-color: #F3F1FE !important;}
.level-dialog.develop.test-6-color .left-box-title-land { background-color: #42279B !important}
.level-dialog.develop.test-6-color .left-box-title { background-color: #42279B !important}

.level-dialog .measure-level-dialog {background-color: #0156B8;color: white;text-align: center; font-weight: bold; font-size: 1.2rem; line-height: 48px; min-height: 48px; cursor: pointer}
.level-dialog .measure-level-dialog.develop {background-color: #247C40}
.level-dialog .measure-level-dialog.develop.level-active { background-color: #32AE59}
.level-dialog .measure-level-dialog.develop.level-def { background-color: #FFF; color: #000; border: 2px solid #ddd}
.level-dialog .measure-level-dialog.dev-button.active { background-color: #247C40 }
.level-dialog .measure-level-dialog.dev-button.disabled { background-color: #ddd }


.log-history .MuiTableCell-head { color: white }
.log-history table td { border: 0; color: white}
.log-history .table-wrap{ width: 900px}


@media screen {
    .print-wrap { height: 0; overflow: hidden}
}

@media print{
    /*
        210 * 297(mm)
        72ppi는 595 * 842(px)
        300ppi는 2480 * 3508(px)
    */
    
    .content-print {
        width: 2480px;
        min-height: 3508px;
        position: relative;
        overflow: hidden;
    }

    .print-h1 { font-size: 50px}
    .print-h2 { font-size: 48px}
    .show-fft { display: none}

    .print-hide { display: none}
    
    .print-landscape {
        width: 3508px;
        min-height: 2480px;
        position: relative; 
        zoom: 10%
    }
    
}

.level-table tbody td {
  padding-top: 10px;
  padding-bottom: 11px;
}

.level-table-c td{
  padding: 12px 24px 12px 16px !important;
  font-size: 1.1rem;
}
.level-table-d td{
  padding: 8px 24px 10px 8px !important;
  font-size: 0.9rem;
}

.content-print {
    width: 2480px;
    min-height: 3508px;
    zoom: 47.93%;
    position: relative;
}

.print-logo {
    padding-left: 10px;
    margin-top: 30px;
    margin-bottom: 30px;
    display: none
}



.border-table { border: 1px solid #ddd; width: 100%; padding-left: 30px}
.border-table th { border-bottom: 1px solid #ddd; border-right: 1px solid #ddd}
.border-table th:last-child { border-bottom: 1px solid #ddd; border-right: 0}
.border-table { border: 1px solid #ddd}
.border-table td { border-bottom: 1px solid #ddd; border-right: 1px solid #ddd; text-align: center}
.border-table td:last-child { border-bottom: 1px solid #ddd; border-right: 0}


.content-print .inner-title { position: relative; padding-left: 48px}
.content-print .inner-title .ico { width: 34px; position: absolute; top: 14px; left:0 }

.print-user-title { font-size: 90px !important; font-weight: bold; text-align: center; margin-bottom: 150px; position: relative}
.print-user-title .subtitle { position: absolute; font-weight:normal; width: 100%; top: 120px; text-align: center;  font-size: 50px !important;}
.print-user-info-table {font-size:48px; height: 930px; width: 100%; color: #2648AC; font-size: 50px;  border: 3px solid #2648AC;}
.print-user-info-table th { background-color: #D6DDEF; border-right: 3px solid #2648AC; border-bottom: 3px solid #2648AC; text-align: center }
.print-user-info-table td { padding-left:20px; text-align: center; color: #000; border-bottom: 3px solid #2648AC;  border-right: 3px solid #2648AC;  }

.print-table { border: 3px solid #000; }
.print-table th { background-color: #D8E2FB; border-right: 3px solid #000; border-bottom: 3px solid #000; text-align: center }
.print-table td { border-bottom: 3px solid #000;  border-right: 3px solid #000; text-align: center; }
.print-table td.c-r-border { border-right: 0px !important;}
.print-table td.text-blue { color: #30468F}
.print-table td.text-danger { color: #ED2929}
.print-table td.text-success{ color: #009500}
.print-table .left-or-right {text-align: left;color: red;font-size: 30px;font-weight: bolder;margin-top: -50px;width: 200px;background: #fff;}


.memory-complex .cal-box {  background-color: #fff; border: 3px solid #ddd; height: 80px; width: 120px; text-align: center; line-height: 80px; font-weight: bold; font-size:3rem; color: #000; border-radius: 10px; display: inline-block; margin: 0px 20px}
.memory-complex .cal-text {
  text-shadow: 3px 3px 0 #FFFFFF,
  -3px -3px 0 #FFFFFF,
  3px -3px 0 #FFFFFF,
  -3px 3px 0 #FFFFFF;
}
/* Default Style */
html, body { width:100%; height:100%; }
html { overflow-y:scroll; font-size:18px;}
body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, form, fieldset, p, button { margin:0; padding:0; }
body, h1, h2, h3, h4, input, select, button, textarea { font-family:'Lato', 'Noto Sans KR', sans-serif;  color:#222; font-style:normal; font-weight:300;}
body { *word-break:break-all; -ms-word-break:break-all; }
img, fieldset, iframe { border:0 none; }
li { list-style:none; }
input, select, button { vertical-align:middle; }
img { vertical-align:top; }
i, em, address { font-style:normal; }
label, button { cursor:pointer; }
button { margin:0; padding:0; }
a { text-decoration:none; color:#222; }
a:hover { text-decoration:none; }
/*a:hover { text-decoration:underline; }*/
button * { position:relative; }
button img { left:-3px; *left:auto; }
option { padding-right:6px; }
caption { display:none; }
legend { *width:0 ;}
table {/* border-collapse:collapse;table-layout:fixed; */ border-collapse:collapse; border-spacing:0; }
textarea { resize:none; }
input[type=checkbox] { margin:0 5px 0 0; padding:0; *margin:-3px 0 0 -3px; vertical-align:-3px; *vertical-align:-3px; }
input[type=radio] { margin:0 5px 0 0; padding:0; *margin:-3px 0 0 -3px; vertical-align:-3px; *vertical-align:-3px; }
textarea { resize:none; }
::-webkit-input-placeholder { color:#ddd; }
:-moz-placeholder { color:#ddd; }
::-moz-placeholder { color:#ddd; }
:-ms-input-placeholder { color:#ddd; }
* {margin:0;padding:0;box-sizing:border-box;-webkit-text-size-adjust:none;}

input[type="text"], input[type="password"], input[type="number"], input[type="tel"], input[type="email"], input[type="button"], input[type="submit"], input[type="reset"] {height:45px; padding:0 ; border:solid 0 #ddd; border-radius:0;}
/*textarea {border:solid 1px #ddd}*/






.wrap-intro { opacity: 1}
.wrap-intro .play-intro { transition-duration: 0.5s; opacity: 1 }
.wrap-intro .hide-intro { transition-duration: 0.5s; opacity: 0 }

.wrap-stage { opacity: 0}
.wrap-stage .play-stage { transition-duration:0.5s; opacity: 1 }
.wrap-stage .hide-stage { transition-duration:0.5s; opacity: 0 }

.tabs { border-bottom: 2px solid #333333; }

.tabs .MuiTabs-indicator { display: none}
.tabs .tab {  padding-bottom: 0; font-size: 24px; font-family: 'Lato', 'Noto Sans KR', sans-serif; margin-bottom: 10px; letter-spacing: -2px}
.tabs .tab::after { content: '|'; padding-left: 25px; color: #E7E8E7; font-size: 20px}
.tabs .tab:last-child::after {content: ''}
.tabs .tab.Mui-selected {font-weight: bold; color: #0055BF}

.tabs.small { border-bottom: 2px solid #ddd;}
.tabs.small .tab { font-size: 18px }


.scorePoint {font-size: 32px; text-align: center; color: white; font-weight: bold; font-family: 'Lato'}
/** hexagon*/
.concentration { width: 150px; height: 150px}
.concentration .score { padding: 0 15px; font-size: 16px; max-width: 800px; margin: 0 auto; }
.concentration .score svg { width: 100%  }

.concentration .score svg text, 
.concentration .score p {
    pointer-events: none;
    text-shadow: 1px 1px 5px #000;
    text-anchor: middle;
    font-size: 120px;
    font-weight: bold;
    fill: rgba(255, 255, 255, 0.90);
    color: rgba(255, 255, 255, 0.90);
    alignment-baseline: middle;
    font-family: 'Lato', 'Noto Sans KR'
}

.concentration .score .row:nth-child(1n+2) {
    margin-top: -10.3%;
}

.concentration .score .row:nth-child(even) :first-child {
    margin-left: 16.666666667%;
}


.synapse .score { background: rgba(0,0,0, 0.5); color: #ddd; padding: 15px;  border-top-left-radius: 15px; border-bottom-left-radius: 15px; width: 130px; font-size: 24px; font-weight: bold}
.synapse .score .box { border-bottom: 1px solid #989EAB; padding-bottom: 10px; margin-bottom: 10px}
.synapse .score .box:last-child { border: 0; padding-bottom: 0; margin-bottom: 0}
.synapse .score .title { text-align: left; font-size: 16px; font-weight: normal}
.synapse .score .value { text-align: center; padding-top: 5px; font-size: 26px; color: white}
.synapse .score .small { font-weight: normal; font-size: 18px; padding-left: 5px}

@media screen and (max-width: 1536px) {
    #header {zoom:0.8}
    #header.app { zoom: 1 !important;}
    
    #cont-wrap { zoom:0.8}
    #cont-wrap.ignore { zoom: 1}
    #cont-wrap.app { zoom: 1 !important;}

    .pop-over { -webkit-transform: translate3d(-0px, 52px, 0px) !important; transform: translate3d(-0px, 52px, 0px) !important;}
    .innerViewport { zoom: .8}
    .innerViewport.z7 { zoom: .7}
    
}

@media screen and (max-width: 1280px) {
    .sv div {position:relative;padding-left:40px; margin:0 auto;height:160px;}
}

.hex-wrap svg {}

.hex-wrap svg g {
  /* fill: #4499a9; */
  fill: #eeeeee;
  fill-opacity: 0.7;
}

.hex-wrap.small svg g {
    fill: #fff;
    fill-opacity: 1;
}

.hex-wrap.big svg g {
    /* fill: #4499a9; */
    fill: #eeeeee;
    fill-opacity: 0.1;
}


.hex-wrap.big svg g.active g{
    /* fill: #4499a9; */
    fill-opacity: 1;
    transition: fill-opacity .5s;
}
.hex-wrap.big svg g.progress g{
    /* fill: #4499a9; */
    fill-opacity: 0.3;
    transition: fill-opacity .5s;
}

.hex-wrap svg g.no-storke g{
    stroke-width: 0 !important;
}

.hex-wrap svg g.blank g {
    fill: #ffffff;
  /* fill: #133b43; */
  /* fill-opacity: 0.3; */
}
.hex-wrap svg g.blank g polygon {
  stroke: #133b43;
  stroke-width: 0;
  /* fill-opacity: 0.3; */
}

.hex-wrap svg g.blank text {
  fill-opacity: 0.1;
}

/*안정 관련 표시 */
.hex-wrap svg g.fill.rest g {
    fill: #03a9f4;
    transition: fill-opacity .5s;
}
.hex-wrap svg g.rest polygon {
    stroke: #29b6f6;
    stroke-width: 0.2;
    transition: fill-opacity .5s;
  }

/*주의력 관련 표시 */
.hex-wrap svg g.fill.attemption g {
    fill: #81c784;
    transition: fill-opacity .5s;
}
.hex-wrap svg g.attemption polygon {
    stroke: #66bb6a;
    stroke-width: 0.2;
    transition: fill-opacity .5s;
  }
   
/*집중 관련 표시 */
.hex-wrap svg g.fill.concentration g {
    fill: #f44336;
    transition: fill-opacity .5s;
}
.hex-wrap svg g.concentration polygon {
    stroke: #ef5350;
    stroke-width: 0.2;
    transition: fill-opacity .5s;
  }

.hex-wrap svg g.fill.brainactive g {
    fill: #B660CD;
    transition: fill-opacity .5s;
}
.hex-wrap svg g.brainactive polygon {
    stroke: #B660CD;
    stroke-width: 0.2;
    transition: fill-opacity .5s;
}




/* .hex-wrap svg g:hover {
  fill: #7be3f6;
  fill-opacity: 0.7;
} */
/* .hex-wrap svg g:hover text {
  fill-opacity: 1;
} */

.hex-wrap svg g polygon {
  stroke: #dddddd;
  stroke-width: 0.2;
  transition: fill-opacity .5s;
}
.hex-wrap svg g text {
  font-size: 0.12em;
  fill: #333;
  fill-opacity: 0.3;
  transition: fill-opacity .5s;
}
.hex-wrap svg path {
  fill: none;
  stroke: #7be3f6;
  stroke-width: 0.2em;
  stroke-opacity: 0.7;
  stroke-linecap: round;
  stroke-linejoin: round;
}




.hex-wrap svg g.rest0 g { fill: #40d5ff}
.hex-wrap svg g.rest1 g { fill: #3dd5ff}
.hex-wrap svg g.rest2 g { fill: #3ad4ff}
.hex-wrap svg g.rest3 g { fill: #37d4ff}
.hex-wrap svg g.rest4 g { fill: #34d3ff}
.hex-wrap svg g.rest5 g { fill: #31d3ff}
.hex-wrap svg g.rest6 g { fill: #2dd2ff}
.hex-wrap svg g.rest7 g { fill: #29d2ff}
.hex-wrap svg g.rest8 g { fill: #25d1ff}
.hex-wrap svg g.rest9 g { fill: #20d1ff}
.hex-wrap svg g.rest10 g { fill: #1ad1ff}
.hex-wrap svg g.rest11 g { fill: #13d0ff}
.hex-wrap svg g.rest12 g { fill: #09d0ff}
.hex-wrap svg g.rest13 g { fill: #00cfff}
.hex-wrap svg g.rest14 g { fill: #00cfff}
.hex-wrap svg g.rest15 g { fill: #00ceff}
.hex-wrap svg g.rest16 g { fill: #00ceff}
.hex-wrap svg g.rest17 g { fill: #00cdff}
.hex-wrap svg g.rest18 g { fill: #00cdff}
.hex-wrap svg g.rest19 g { fill: #00ccff}
.hex-wrap svg g.rest20 g { fill: #00ccff}
.hex-wrap svg g.rest21 g { fill: #00ccff}
.hex-wrap svg g.rest22 g { fill: #00cbff}
.hex-wrap svg g.rest23 g { fill: #00cbff}
.hex-wrap svg g.rest24 g { fill: #00caff}
.hex-wrap svg g.rest25 g { fill: #00caff}
.hex-wrap svg g.rest26 g { fill: #00c9ff}
.hex-wrap svg g.rest27 g { fill: #00c9ff}
.hex-wrap svg g.rest28 g { fill: #00c8ff}
.hex-wrap svg g.rest29 g { fill: #00c8ff}
.hex-wrap svg g.rest30 g { fill: #00c7ff}
.hex-wrap svg g.rest31 g { fill: #00c7ff}
.hex-wrap svg g.rest32 g { fill: #00c6ff}
.hex-wrap svg g.rest33 g { fill: #00c6ff}
.hex-wrap svg g.rest34 g { fill: #00c5ff}
.hex-wrap svg g.rest35 g { fill: #00c5ff}
.hex-wrap svg g.rest36 g { fill: #00c4ff}
.hex-wrap svg g.rest37 g { fill: #00c4ff}
.hex-wrap svg g.rest38 g { fill: #00c4ff}
.hex-wrap svg g.rest39 g { fill: #00c3ff}
.hex-wrap svg g.rest40 g { fill: #00c3ff}
.hex-wrap svg g.rest41 g { fill: #00c2ff}
.hex-wrap svg g.rest42 g { fill: #00c2ff}
.hex-wrap svg g.rest43 g { fill: #00c1ff}
.hex-wrap svg g.rest44 g { fill: #00c1ff}
.hex-wrap svg g.rest45 g { fill: #00c0ff}
.hex-wrap svg g.rest46 g { fill: #00c0ff}
.hex-wrap svg g.rest47 g { fill: #00bfff}
.hex-wrap svg g.rest48 g { fill: #00bfff}
.hex-wrap svg g.rest49 g { fill: #00beff}
.hex-wrap svg g.rest50 g { fill: #00beff}
.hex-wrap svg g.rest51 g { fill: #00bdff}
.hex-wrap svg g.rest52 g { fill: #00bdff}
.hex-wrap svg g.rest53 g { fill: #00bcff}
.hex-wrap svg g.rest54 g { fill: #00bcff}
.hex-wrap svg g.rest55 g { fill: #00bbff}
.hex-wrap svg g.rest56 g { fill: #00bbff}
.hex-wrap svg g.rest57 g { fill: #00baff}
.hex-wrap svg g.rest58 g { fill: #00baff}
.hex-wrap svg g.rest59 g { fill: #00b9ff}
.hex-wrap svg g.rest60 g { fill: #00b9ff}
.hex-wrap svg g.rest61 g { fill: #00b8ff}
.hex-wrap svg g.rest62 g { fill: #00b7ff}
.hex-wrap svg g.rest63 g { fill: #00b7ff}
.hex-wrap svg g.rest64 g { fill: #00b6ff}
.hex-wrap svg g.rest65 g { fill: #00b6ff}
.hex-wrap svg g.rest66 g { fill: #00b5ff}
.hex-wrap svg g.rest67 g { fill: #00b5ff}
.hex-wrap svg g.rest68 g { fill: #00b4ff}
.hex-wrap svg g.rest69 g { fill: #00b4ff}
.hex-wrap svg g.rest70 g { fill: #00b3ff}
.hex-wrap svg g.rest71 g { fill: #00b3ff}
.hex-wrap svg g.rest72 g { fill: #00b2ff}
.hex-wrap svg g.rest73 g { fill: #00b2ff}
.hex-wrap svg g.rest74 g { fill: #00b1ff}
.hex-wrap svg g.rest75 g { fill: #00b1ff}
.hex-wrap svg g.rest76 g { fill: #00b0ff}
.hex-wrap svg g.rest77 g { fill: #00b0ff}
.hex-wrap svg g.rest78 g { fill: #00afff}
.hex-wrap svg g.rest79 g { fill: #00aeff}
.hex-wrap svg g.rest80 g { fill: #00aeff}
.hex-wrap svg g.rest81 g { fill: #00adff}
.hex-wrap svg g.rest82 g { fill: #00adff}
.hex-wrap svg g.rest83 g { fill: #00acff}
.hex-wrap svg g.rest84 g { fill: #00acff}
.hex-wrap svg g.rest85 g { fill: #00abff}
.hex-wrap svg g.rest86 g { fill: #00abff}
.hex-wrap svg g.rest87 g { fill: #00aaff}
.hex-wrap svg g.rest88 g { fill: #00a9ff}
.hex-wrap svg g.rest89 g { fill: #00a9ff}
.hex-wrap svg g.rest90 g { fill: #00a8ff}
.hex-wrap svg g.rest91 g { fill: #00a8ff}
.hex-wrap svg g.rest92 g { fill: #00a7ff}
.hex-wrap svg g.rest93 g { fill: #00a7ff}
.hex-wrap svg g.rest94 g { fill: #00a6ff}
.hex-wrap svg g.rest95 g { fill: #00a5ff}
.hex-wrap svg g.rest96 g { fill: #00a5ff}
.hex-wrap svg g.rest97 g { fill: #00a4ff}
.hex-wrap svg g.rest98 g { fill: #00a4ff}
.hex-wrap svg g.rest99 g { fill: #00a3ff}
.hex-wrap svg g.rest100 g { fill: #00a3ff}
.hex-wrap svg g.rest101 g { fill: #00a2ff}
.hex-wrap svg g.rest102 g { fill: #00a1ff}
.hex-wrap svg g.rest103 g { fill: #00a1ff}
.hex-wrap svg g.rest104 g { fill: #00a0ff}
.hex-wrap svg g.rest105 g { fill: #00a0ff}
.hex-wrap svg g.rest106 g { fill: #009fff}
.hex-wrap svg g.rest107 g { fill: #009eff}
.hex-wrap svg g.rest108 g { fill: #009eff}
.hex-wrap svg g.rest109 g { fill: #009dff}
.hex-wrap svg g.rest110 g { fill: #009dff}
.hex-wrap svg g.rest111 g { fill: #009cff}
.hex-wrap svg g.rest112 g { fill: #009bff}
.hex-wrap svg g.rest113 g { fill: #009bff}
.hex-wrap svg g.rest114 g { fill: #009aff}
.hex-wrap svg g.rest115 g { fill: #009aff}
.hex-wrap svg g.rest116 g { fill: #0099ff}
.hex-wrap svg g.rest117 g { fill: #0098ff}
.hex-wrap svg g.rest118 g { fill: #0098ff}
.hex-wrap svg g.rest119 g { fill: #0097ff}
.hex-wrap svg g.rest120 g { fill: #0097ff}
.hex-wrap svg g.rest121 g { fill: #0096ff}
.hex-wrap svg g.rest122 g { fill: #0095ff}
.hex-wrap svg g.rest123 g { fill: #0095ff}
.hex-wrap svg g.rest124 g { fill: #0094ff}
.hex-wrap svg g.rest125 g { fill: #0093ff}
.hex-wrap svg g.rest126 g { fill: #0093ff}
.hex-wrap svg g.rest127 g { fill: #0092ff}
.hex-wrap svg g.rest128 g { fill: #0091ff}
.hex-wrap svg g.rest129 g { fill: #0091ff}
.hex-wrap svg g.rest130 g { fill: #0090ff}
.hex-wrap svg g.rest131 g { fill: #008fff}
.hex-wrap svg g.rest132 g { fill: #008fff}
.hex-wrap svg g.rest133 g { fill: #008eff}
.hex-wrap svg g.rest134 g { fill: #008eff}
.hex-wrap svg g.rest135 g { fill: #008dff}
.hex-wrap svg g.rest136 g { fill: #008cff}
.hex-wrap svg g.rest137 g { fill: #008cff}
.hex-wrap svg g.rest138 g { fill: #008bff}
.hex-wrap svg g.rest139 g { fill: #008aff}
.hex-wrap svg g.rest140 g { fill: #0089ff}
.hex-wrap svg g.rest141 g { fill: #0089ff}
.hex-wrap svg g.rest142 g { fill: #0088ff}
.hex-wrap svg g.rest143 g { fill: #0087ff}
.hex-wrap svg g.rest144 g { fill: #0087ff}
.hex-wrap svg g.rest145 g { fill: #0086ff}
.hex-wrap svg g.rest146 g { fill: #0085ff}
.hex-wrap svg g.rest147 g { fill: #0085ff}
.hex-wrap svg g.rest148 g { fill: #0084ff}
.hex-wrap svg g.rest149 g { fill: #0083ff}
.hex-wrap svg g.rest150 g { fill: #0083ff}
.hex-wrap svg g.rest151 g { fill: #0082ff}
.hex-wrap svg g.rest152 g { fill: #0081ff}
.hex-wrap svg g.rest153 g { fill: #0080ff}
.hex-wrap svg g.rest154 g { fill: #0080ff}
.hex-wrap svg g.rest155 g { fill: #007fff}
.hex-wrap svg g.rest156 g { fill: #007eff}
.hex-wrap svg g.rest157 g { fill: #007dff}
.hex-wrap svg g.rest158 g { fill: #007dff}
.hex-wrap svg g.rest159 g { fill: #007cff}
.hex-wrap svg g.rest160 g { fill: #007bff}
.hex-wrap svg g.rest161 g { fill: #007aff}
.hex-wrap svg g.rest162 g { fill: #007aff}
.hex-wrap svg g.rest163 g { fill: #0079ff}
.hex-wrap svg g.rest164 g { fill: #0078ff}
.hex-wrap svg g.rest165 g { fill: #0077ff}
.hex-wrap svg g.rest166 g { fill: #0077ff}
.hex-wrap svg g.rest167 g { fill: #0076ff}
.hex-wrap svg g.rest168 g { fill: #0075ff}
.hex-wrap svg g.rest169 g { fill: #0074ff}
.hex-wrap svg g.rest170 g { fill: #0073ff}
.hex-wrap svg g.rest171 g { fill: #0073ff}
.hex-wrap svg g.rest172 g { fill: #0072ff}
.hex-wrap svg g.rest173 g { fill: #0071ff}
.hex-wrap svg g.rest174 g { fill: #0070ff}
.hex-wrap svg g.rest175 g { fill: #006fff}
.hex-wrap svg g.rest176 g { fill: #006fff}
.hex-wrap svg g.rest177 g { fill: #006eff}
.hex-wrap svg g.rest178 g { fill: #006dff}
.hex-wrap svg g.rest179 g { fill: #006cff}
.hex-wrap svg g.rest180 g { fill: #006bff}
.hex-wrap svg g.rest181 g { fill: #006aff}
.hex-wrap svg g.rest182 g { fill: #0069ff}
.hex-wrap svg g.rest183 g { fill: #0069ff}
.hex-wrap svg g.rest184 g { fill: #0068ff}
.hex-wrap svg g.rest185 g { fill: #0067ff}
.hex-wrap svg g.rest186 g { fill: #0066ff}
.hex-wrap svg g.rest187 g { fill: #0065ff}
.hex-wrap svg g.rest188 g { fill: #0064ff}
.hex-wrap svg g.rest189 g { fill: #0063ff}
.hex-wrap svg g.rest190 g { fill: #0062ff}
.hex-wrap svg g.rest191 g { fill: #0061ff}
.hex-wrap svg g.rest192 g { fill: #0060ff}
.hex-wrap svg g.rest193 g { fill: #005fff}
.hex-wrap svg g.rest194 g { fill: #005eff}
.hex-wrap svg g.rest195 g { fill: #005dff}
.hex-wrap svg g.rest196 g { fill: #045cff}
.hex-wrap svg g.rest197 g { fill: #0f5bff}
.hex-wrap svg g.rest198 g { fill: #175aff}
.hex-wrap svg g.rest199 g { fill: #1d59ff}
.hex-wrap svg g.rest200 g { fill: #2258ff}
.hex-wrap svg g.rest201 g { fill: #2657ff}
.hex-wrap svg g.rest202 g { fill: #2b56ff}
.hex-wrap svg g.rest203 g { fill: #2e55ff}
.hex-wrap svg g.rest204 g { fill: #3254ff}
.hex-wrap svg g.rest205 g { fill: #3553ff}
.hex-wrap svg g.rest206 g { fill: #3852ff}
.hex-wrap svg g.rest207 g { fill: #3b51ff}
.hex-wrap svg g.rest208 g { fill: #3d50ff}
.hex-wrap svg g.rest209 g { fill: #404fff}
.hex-wrap svg g.rest210 g { fill: #434dff}
.hex-wrap svg g.rest211 g { fill: #454cff}
.hex-wrap svg g.rest212 g { fill: #474bff}
.hex-wrap svg g.rest213 g { fill: #4a4aff}
.hex-wrap svg g.rest214 g { fill: #4c49ff}
.hex-wrap svg g.rest215 g { fill: #4e47ff}
.hex-wrap svg g.rest216 g { fill: #5046fe}


.hex-wrap svg g.attemption0 g { fill: #41fca2}
.hex-wrap svg g.attemption1 g { fill: #3ffca3}
.hex-wrap svg g.attemption2 g { fill: #3cfca4}
.hex-wrap svg g.attemption3 g { fill: #3afca5}
.hex-wrap svg g.attemption4 g { fill: #37fca5}
.hex-wrap svg g.attemption5 g { fill: #34fba6}
.hex-wrap svg g.attemption6 g { fill: #31fba7}
.hex-wrap svg g.attemption7 g { fill: #2efba8}
.hex-wrap svg g.attemption8 g { fill: #2bfba9}
.hex-wrap svg g.attemption9 g { fill: #28fbaa}
.hex-wrap svg g.attemption10 g { fill: #24fbab}
.hex-wrap svg g.attemption11 g { fill: #20fbac}
.hex-wrap svg g.attemption12 g { fill: #1bfbac}
.hex-wrap svg g.attemption13 g { fill: #15faad}
.hex-wrap svg g.attemption14 g { fill: #0efaae}
.hex-wrap svg g.attemption15 g { fill: #04faaf}
.hex-wrap svg g.attemption16 g { fill: #00fab0}
.hex-wrap svg g.attemption17 g { fill: #00fab1}
.hex-wrap svg g.attemption18 g { fill: #00fab1}
.hex-wrap svg g.attemption19 g { fill: #00fab2}
.hex-wrap svg g.attemption20 g { fill: #00f9b3}
.hex-wrap svg g.attemption21 g { fill: #00f9b4}
.hex-wrap svg g.attemption22 g { fill: #00f9b5}
.hex-wrap svg g.attemption23 g { fill: #00f9b6}
.hex-wrap svg g.attemption24 g { fill: #00f9b6}
.hex-wrap svg g.attemption25 g { fill: #00f9b7}
.hex-wrap svg g.attemption26 g { fill: #00f9b8}
.hex-wrap svg g.attemption27 g { fill: #00f8b9}
.hex-wrap svg g.attemption28 g { fill: #00f8ba}
.hex-wrap svg g.attemption29 g { fill: #00f8ba}
.hex-wrap svg g.attemption30 g { fill: #00f8bb}
.hex-wrap svg g.attemption31 g { fill: #00f8bc}
.hex-wrap svg g.attemption32 g { fill: #00f8bd}
.hex-wrap svg g.attemption33 g { fill: #00f7be}
.hex-wrap svg g.attemption34 g { fill: #00f7be}
.hex-wrap svg g.attemption35 g { fill: #00f7bf}
.hex-wrap svg g.attemption36 g { fill: #00f7c0}
.hex-wrap svg g.attemption37 g { fill: #00f7c1}
.hex-wrap svg g.attemption38 g { fill: #00f7c2}
.hex-wrap svg g.attemption39 g { fill: #00f6c2}
.hex-wrap svg g.attemption40 g { fill: #00f6c3}
.hex-wrap svg g.attemption41 g { fill: #00f6c4}
.hex-wrap svg g.attemption42 g { fill: #00f6c5}
.hex-wrap svg g.attemption43 g { fill: #00f6c5}
.hex-wrap svg g.attemption44 g { fill: #00f6c6}
.hex-wrap svg g.attemption45 g { fill: #00f5c7}
.hex-wrap svg g.attemption46 g { fill: #00f5c8}
.hex-wrap svg g.attemption47 g { fill: #00f5c8}
.hex-wrap svg g.attemption48 g { fill: #00f5c9}
.hex-wrap svg g.attemption49 g { fill: #00f5ca}
.hex-wrap svg g.attemption50 g { fill: #00f5cb}
.hex-wrap svg g.attemption51 g { fill: #00f4cb}
.hex-wrap svg g.attemption52 g { fill: #00f4cc}
.hex-wrap svg g.attemption53 g { fill: #00f4cd}
.hex-wrap svg g.attemption54 g { fill: #00f4ce}
.hex-wrap svg g.attemption55 g { fill: #00f4ce}
.hex-wrap svg g.attemption56 g { fill: #00f3cf}
.hex-wrap svg g.attemption57 g { fill: #00f3d0}
.hex-wrap svg g.attemption58 g { fill: #00f3d0}
.hex-wrap svg g.attemption59 g { fill: #00f3d1}
.hex-wrap svg g.attemption60 g { fill: #00f3d2}
.hex-wrap svg g.attemption61 g { fill: #00f2d3}
.hex-wrap svg g.attemption62 g { fill: #00f2d3}
.hex-wrap svg g.attemption63 g { fill: #00f2d4}
.hex-wrap svg g.attemption64 g { fill: #00f2d5}
.hex-wrap svg g.attemption65 g { fill: #00f2d5}
.hex-wrap svg g.attemption66 g { fill: #00f1d6}
.hex-wrap svg g.attemption67 g { fill: #00f1d7}
.hex-wrap svg g.attemption68 g { fill: #00f1d7}
.hex-wrap svg g.attemption69 g { fill: #00f1d8}
.hex-wrap svg g.attemption70 g { fill: #00f1d9}
.hex-wrap svg g.attemption71 g { fill: #00f0d9}
.hex-wrap svg g.attemption72 g { fill: #00f0da}
.hex-wrap svg g.attemption73 g { fill: #00f0db}
.hex-wrap svg g.attemption74 g { fill: #00f0db}
.hex-wrap svg g.attemption75 g { fill: #00f0dc}
.hex-wrap svg g.attemption76 g { fill: #00efdc}
.hex-wrap svg g.attemption77 g { fill: #00efdd}
.hex-wrap svg g.attemption78 g { fill: #00efde}
.hex-wrap svg g.attemption79 g { fill: #00efde}
.hex-wrap svg g.attemption80 g { fill: #00efdf}
.hex-wrap svg g.attemption81 g { fill: #00eee0}
.hex-wrap svg g.attemption82 g { fill: #00eee0}
.hex-wrap svg g.attemption83 g { fill: #00eee1}
.hex-wrap svg g.attemption84 g { fill: #00eee1}
.hex-wrap svg g.attemption85 g { fill: #00ede2}
.hex-wrap svg g.attemption86 g { fill: #00ede3}
.hex-wrap svg g.attemption87 g { fill: #00ede3}
.hex-wrap svg g.attemption88 g { fill: #00ede4}
.hex-wrap svg g.attemption89 g { fill: #00ede4}
.hex-wrap svg g.attemption90 g { fill: #00ece5}
.hex-wrap svg g.attemption91 g { fill: #00ece5}
.hex-wrap svg g.attemption92 g { fill: #00ece6}
.hex-wrap svg g.attemption93 g { fill: #00ece6}
.hex-wrap svg g.attemption94 g { fill: #00ebe7}
.hex-wrap svg g.attemption95 g { fill: #00ebe8}
.hex-wrap svg g.attemption96 g { fill: #00ebe8}
.hex-wrap svg g.attemption97 g { fill: #00ebe9}
.hex-wrap svg g.attemption98 g { fill: #00ebe9}
.hex-wrap svg g.attemption99 g { fill: #00eaea}
.hex-wrap svg g.attemption100 g { fill: #00eaea}
.hex-wrap svg g.attemption101 g { fill: #00eaeb}
.hex-wrap svg g.attemption102 g { fill: #00eaeb}
.hex-wrap svg g.attemption103 g { fill: #00e9ec}
.hex-wrap svg g.attemption104 g { fill: #00e9ec}
.hex-wrap svg g.attemption105 g { fill: #00e9ed}
.hex-wrap svg g.attemption106 g { fill: #00e9ed}
.hex-wrap svg g.attemption107 g { fill: #00e8ee}
.hex-wrap svg g.attemption108 g { fill: #00e8ee}
.hex-wrap svg g.attemption109 g { fill: #00e8ef}
.hex-wrap svg g.attemption110 g { fill: #00e8ef}
.hex-wrap svg g.attemption111 g { fill: #00e7ef}
.hex-wrap svg g.attemption112 g { fill: #00e7f0}
.hex-wrap svg g.attemption113 g { fill: #00e7f0}
.hex-wrap svg g.attemption114 g { fill: #00e7f1}
.hex-wrap svg g.attemption115 g { fill: #00e6f1}
.hex-wrap svg g.attemption116 g { fill: #00e6f2}
.hex-wrap svg g.attemption117 g { fill: #00e6f2}
.hex-wrap svg g.attemption118 g { fill: #00e6f2}
.hex-wrap svg g.attemption119 g { fill: #00e5f3}
.hex-wrap svg g.attemption120 g { fill: #00e5f3}
.hex-wrap svg g.attemption121 g { fill: #00e5f4}
.hex-wrap svg g.attemption122 g { fill: #00e5f4}
.hex-wrap svg g.attemption123 g { fill: #00e4f4}
.hex-wrap svg g.attemption124 g { fill: #00e4f5}
.hex-wrap svg g.attemption125 g { fill: #00e4f5}
.hex-wrap svg g.attemption126 g { fill: #00e4f6}
.hex-wrap svg g.attemption127 g { fill: #00e3f6}
.hex-wrap svg g.attemption128 g { fill: #00e3f6}
.hex-wrap svg g.attemption129 g { fill: #00e3f7}
.hex-wrap svg g.attemption130 g { fill: #00e3f7}
.hex-wrap svg g.attemption131 g { fill: #00e2f7}
.hex-wrap svg g.attemption132 g { fill: #00e2f8}
.hex-wrap svg g.attemption133 g { fill: #00e2f8}
.hex-wrap svg g.attemption134 g { fill: #00e1f8}
.hex-wrap svg g.attemption135 g { fill: #00e1f9}
.hex-wrap svg g.attemption136 g { fill: #00e1f9}
.hex-wrap svg g.attemption137 g { fill: #00e1f9}
.hex-wrap svg g.attemption138 g { fill: #00e0fa}
.hex-wrap svg g.attemption139 g { fill: #00e0fa}
.hex-wrap svg g.attemption140 g { fill: #00e0fa}
.hex-wrap svg g.attemption141 g { fill: #00e0fa}
.hex-wrap svg g.attemption142 g { fill: #00dffb}
.hex-wrap svg g.attemption143 g { fill: #00dffb}
.hex-wrap svg g.attemption144 g { fill: #00dffb}
.hex-wrap svg g.attemption145 g { fill: #00defc}
.hex-wrap svg g.attemption146 g { fill: #00defc}
.hex-wrap svg g.attemption147 g { fill: #00defc}
.hex-wrap svg g.attemption148 g { fill: #00defc}
.hex-wrap svg g.attemption149 g { fill: #00ddfc}
.hex-wrap svg g.attemption150 g { fill: #00ddfd}
.hex-wrap svg g.attemption151 g { fill: #00ddfd}
.hex-wrap svg g.attemption152 g { fill: #00dcfd}
.hex-wrap svg g.attemption153 g { fill: #00dcfd}
.hex-wrap svg g.attemption154 g { fill: #00dcfe}
.hex-wrap svg g.attemption155 g { fill: #00dcfe}
.hex-wrap svg g.attemption156 g { fill: #00dbfe}
.hex-wrap svg g.attemption157 g { fill: #00dbfe}
.hex-wrap svg g.attemption158 g { fill: #00dbfe}
.hex-wrap svg g.attemption159 g { fill: #00daff}
.hex-wrap svg g.attemption160 g { fill: #00daff}
.hex-wrap svg g.attemption161 g { fill: #00daff}
.hex-wrap svg g.attemption162 g { fill: #00daff}
.hex-wrap svg g.attemption163 g { fill: #00d9ff}
.hex-wrap svg g.attemption164 g { fill: #00d9ff}
.hex-wrap svg g.attemption165 g { fill: #00d9ff}
.hex-wrap svg g.attemption166 g { fill: #00d8ff}
.hex-wrap svg g.attemption167 g { fill: #00d8ff}
.hex-wrap svg g.attemption168 g { fill: #00d8ff}
.hex-wrap svg g.attemption169 g { fill: #00d8ff}
.hex-wrap svg g.attemption170 g { fill: #00d7ff}
.hex-wrap svg g.attemption171 g { fill: #00d7ff}
.hex-wrap svg g.attemption172 g { fill: #00d7ff}
.hex-wrap svg g.attemption173 g { fill: #00d6ff}
.hex-wrap svg g.attemption174 g { fill: #00d6ff}
.hex-wrap svg g.attemption175 g { fill: #00d6ff}
.hex-wrap svg g.attemption176 g { fill: #00d5ff}
.hex-wrap svg g.attemption177 g { fill: #00d5ff}
.hex-wrap svg g.attemption178 g { fill: #00d5ff}
.hex-wrap svg g.attemption179 g { fill: #00d5ff}
.hex-wrap svg g.attemption180 g { fill: #00d4ff}
.hex-wrap svg g.attemption181 g { fill: #00d4ff}
.hex-wrap svg g.attemption182 g { fill: #00d4ff}
.hex-wrap svg g.attemption183 g { fill: #00d3ff}
.hex-wrap svg g.attemption184 g { fill: #00d3ff}
.hex-wrap svg g.attemption185 g { fill: #00d3ff}
.hex-wrap svg g.attemption186 g { fill: #00d2ff}
.hex-wrap svg g.attemption187 g { fill: #00d2ff}
.hex-wrap svg g.attemption188 g { fill: #00d2ff}
.hex-wrap svg g.attemption189 g { fill: #00d1ff}
.hex-wrap svg g.attemption190 g { fill: #00d1ff}
.hex-wrap svg g.attemption191 g { fill: #00d1ff}
.hex-wrap svg g.attemption192 g { fill: #00d1ff}
.hex-wrap svg g.attemption193 g { fill: #00d0ff}
.hex-wrap svg g.attemption194 g { fill: #00d0ff}
.hex-wrap svg g.attemption195 g { fill: #00d0ff}
.hex-wrap svg g.attemption196 g { fill: #00cfff}
.hex-wrap svg g.attemption197 g { fill: #00cfff}
.hex-wrap svg g.attemption198 g { fill: #09cfff}
.hex-wrap svg g.attemption199 g { fill: #11ceff}
.hex-wrap svg g.attemption200 g { fill: #17ceff}
.hex-wrap svg g.attemption201 g { fill: #1bceff}
.hex-wrap svg g.attemption202 g { fill: #1fcdff}
.hex-wrap svg g.attemption203 g { fill: #23cdff}
.hex-wrap svg g.attemption204 g { fill: #26cdff}
.hex-wrap svg g.attemption205 g { fill: #29ccff}
.hex-wrap svg g.attemption206 g { fill: #2cccff}
.hex-wrap svg g.attemption207 g { fill: #2fccff}
.hex-wrap svg g.attemption208 g { fill: #31ccff}
.hex-wrap svg g.attemption209 g { fill: #34cbff}
.hex-wrap svg g.attemption210 g { fill: #36cbff}
.hex-wrap svg g.attemption211 g { fill: #38cbff}
.hex-wrap svg g.attemption212 g { fill: #3acaff}
.hex-wrap svg g.attemption213 g { fill: #3ccaff}
.hex-wrap svg g.attemption214 g { fill: #3ecaff}
.hex-wrap svg g.attemption215 g { fill: #40c9ff}
.hex-wrap svg g.attemption216 g { fill: #42c9ff}


.hex-wrap svg g.concentration0 g { fill: #fd743e}
.hex-wrap svg g.concentration1 g { fill: #fd753e}
.hex-wrap svg g.concentration2 g { fill: #fd753e}
.hex-wrap svg g.concentration3 g { fill: #fe763d}
.hex-wrap svg g.concentration4 g { fill: #fe773d}
.hex-wrap svg g.concentration5 g { fill: #fe773d}
.hex-wrap svg g.concentration6 g { fill: #fe783d}
.hex-wrap svg g.concentration7 g { fill: #ff783c}
.hex-wrap svg g.concentration8 g { fill: #ff793c}
.hex-wrap svg g.concentration9 g { fill: #ff7a3c}
.hex-wrap svg g.concentration10 g { fill: #ff7a3c}
.hex-wrap svg g.concentration11 g { fill: #ff7b3c}
.hex-wrap svg g.concentration12 g { fill: #ff7c3b}
.hex-wrap svg g.concentration13 g { fill: #ff7c3b}
.hex-wrap svg g.concentration14 g { fill: #ff7d3b}
.hex-wrap svg g.concentration15 g { fill: #ff7e3b}
.hex-wrap svg g.concentration16 g { fill: #ff7e3b}
.hex-wrap svg g.concentration17 g { fill: #ff7f3a}
.hex-wrap svg g.concentration18 g { fill: #ff7f3a}
.hex-wrap svg g.concentration19 g { fill: #ff803a}
.hex-wrap svg g.concentration20 g { fill: #ff813a}
.hex-wrap svg g.concentration21 g { fill: #ff8139}
.hex-wrap svg g.concentration22 g { fill: #ff8239}
.hex-wrap svg g.concentration23 g { fill: #ff8339}
.hex-wrap svg g.concentration24 g { fill: #ff8339}
.hex-wrap svg g.concentration25 g { fill: #ff8439}
.hex-wrap svg g.concentration26 g { fill: #ff8538}
.hex-wrap svg g.concentration27 g { fill: #ff8538}
.hex-wrap svg g.concentration28 g { fill: #ff8638}
.hex-wrap svg g.concentration29 g { fill: #ff8638}
.hex-wrap svg g.concentration30 g { fill: #ff8738}
.hex-wrap svg g.concentration31 g { fill: #ff8837}
.hex-wrap svg g.concentration32 g { fill: #ff8837}
.hex-wrap svg g.concentration33 g { fill: #ff8937}
.hex-wrap svg g.concentration34 g { fill: #ff8a37}
.hex-wrap svg g.concentration35 g { fill: #ff8a36}
.hex-wrap svg g.concentration36 g { fill: #ff8b36}
.hex-wrap svg g.concentration37 g { fill: #ff8c36}
.hex-wrap svg g.concentration38 g { fill: #ff8c36}
.hex-wrap svg g.concentration39 g { fill: #ff8d36}
.hex-wrap svg g.concentration40 g { fill: #ff8e35}
.hex-wrap svg g.concentration41 g { fill: #ff8e35}
.hex-wrap svg g.concentration42 g { fill: #ff8f35}
.hex-wrap svg g.concentration43 g { fill: #ff8f35}
.hex-wrap svg g.concentration44 g { fill: #ff9035}
.hex-wrap svg g.concentration45 g { fill: #ff9134}
.hex-wrap svg g.concentration46 g { fill: #ff9134}
.hex-wrap svg g.concentration47 g { fill: #ff9234}
.hex-wrap svg g.concentration48 g { fill: #ff9334}
.hex-wrap svg g.concentration49 g { fill: #ff9334}
.hex-wrap svg g.concentration50 g { fill: #ff9434}
.hex-wrap svg g.concentration51 g { fill: #ff9533}
.hex-wrap svg g.concentration52 g { fill: #ff9533}
.hex-wrap svg g.concentration53 g { fill: #ff9633}
.hex-wrap svg g.concentration54 g { fill: #ff9733}
.hex-wrap svg g.concentration55 g { fill: #ff9733}
.hex-wrap svg g.concentration56 g { fill: #ff9832}
.hex-wrap svg g.concentration57 g { fill: #ff9832}
.hex-wrap svg g.concentration58 g { fill: #ff9932}
.hex-wrap svg g.concentration59 g { fill: #ff9a32}
.hex-wrap svg g.concentration60 g { fill: #ff9a32}
.hex-wrap svg g.concentration61 g { fill: #ff9b32}
.hex-wrap svg g.concentration62 g { fill: #ff9c31}
.hex-wrap svg g.concentration63 g { fill: #ff9c31}
.hex-wrap svg g.concentration64 g { fill: #ff9d31}
.hex-wrap svg g.concentration65 g { fill: #ff9e31}
.hex-wrap svg g.concentration66 g { fill: #ff9e31}
.hex-wrap svg g.concentration67 g { fill: #ff9f31}
.hex-wrap svg g.concentration68 g { fill: #ffa030}
.hex-wrap svg g.concentration69 g { fill: #ffa030}
.hex-wrap svg g.concentration70 g { fill: #ffa130}
.hex-wrap svg g.concentration71 g { fill: #ffa130}
.hex-wrap svg g.concentration72 g { fill: #ffa230}
.hex-wrap svg g.concentration73 g { fill: #ffa330}
.hex-wrap svg g.concentration74 g { fill: #ffa330}
.hex-wrap svg g.concentration75 g { fill: #ffa42f}
.hex-wrap svg g.concentration76 g { fill: #ffa52f}
.hex-wrap svg g.concentration77 g { fill: #ffa52f}
.hex-wrap svg g.concentration78 g { fill: #ffa62f}
.hex-wrap svg g.concentration79 g { fill: #ffa72f}
.hex-wrap svg g.concentration80 g { fill: #ffa72f}
.hex-wrap svg g.concentration81 g { fill: #ffa82f}
.hex-wrap svg g.concentration82 g { fill: #ffa92e}
.hex-wrap svg g.concentration83 g { fill: #ffa92e}
.hex-wrap svg g.concentration84 g { fill: #ffaa2e}
.hex-wrap svg g.concentration85 g { fill: #ffab2e}
.hex-wrap svg g.concentration86 g { fill: #ffab2e}
.hex-wrap svg g.concentration87 g { fill: #ffac2e}
.hex-wrap svg g.concentration88 g { fill: #ffac2e}
.hex-wrap svg g.concentration89 g { fill: #ffad2e}
.hex-wrap svg g.concentration90 g { fill: #ffae2e}
.hex-wrap svg g.concentration91 g { fill: #ffae2e}
.hex-wrap svg g.concentration92 g { fill: #ffaf2d}
.hex-wrap svg g.concentration93 g { fill: #ffb02d}
.hex-wrap svg g.concentration94 g { fill: #ffb02d}
.hex-wrap svg g.concentration95 g { fill: #ffb12d}
.hex-wrap svg g.concentration96 g { fill: #ffb22d}
.hex-wrap svg g.concentration97 g { fill: #ffb22d}
.hex-wrap svg g.concentration98 g { fill: #ffb32d}
.hex-wrap svg g.concentration99 g { fill: #ffb42d}
.hex-wrap svg g.concentration100 g { fill: #ffb42d}
.hex-wrap svg g.concentration101 g { fill: #ffb52d}
.hex-wrap svg g.concentration102 g { fill: #ffb62d}
.hex-wrap svg g.concentration103 g { fill: #ffb62d}
.hex-wrap svg g.concentration104 g { fill: #ffb72d}
.hex-wrap svg g.concentration105 g { fill: #ffb82d}
.hex-wrap svg g.concentration106 g { fill: #ffb82d}
.hex-wrap svg g.concentration107 g { fill: #ffb92d}
.hex-wrap svg g.concentration108 g { fill: #ffb92d}
.hex-wrap svg g.concentration109 g { fill: #ffba2d}
.hex-wrap svg g.concentration110 g { fill: #ffbb2d}
.hex-wrap svg g.concentration111 g { fill: #ffbb2d}
.hex-wrap svg g.concentration112 g { fill: #ffbc2d}
.hex-wrap svg g.concentration113 g { fill: #ffbd2d}
.hex-wrap svg g.concentration114 g { fill: #ffbd2d}
.hex-wrap svg g.concentration115 g { fill: #ffbe2d}
.hex-wrap svg g.concentration116 g { fill: #ffbf2d}
.hex-wrap svg g.concentration117 g { fill: #ffbf2d}
.hex-wrap svg g.concentration118 g { fill: #ffc02d}
.hex-wrap svg g.concentration119 g { fill: #ffc12d}
.hex-wrap svg g.concentration120 g { fill: #ffc12d}
.hex-wrap svg g.concentration121 g { fill: #ffc22d}
.hex-wrap svg g.concentration122 g { fill: #ffc32d}
.hex-wrap svg g.concentration123 g { fill: #ffc32d}
.hex-wrap svg g.concentration124 g { fill: #ffc42d}
.hex-wrap svg g.concentration125 g { fill: #ffc42d}
.hex-wrap svg g.concentration126 g { fill: #ffc52d}
.hex-wrap svg g.concentration127 g { fill: #ffc62d}
.hex-wrap svg g.concentration128 g { fill: #ffc62e}
.hex-wrap svg g.concentration129 g { fill: #ffc72e}
.hex-wrap svg g.concentration130 g { fill: #ffc82e}
.hex-wrap svg g.concentration131 g { fill: #ffc82e}
.hex-wrap svg g.concentration132 g { fill: #ffc92e}
.hex-wrap svg g.concentration133 g { fill: #ffca2e}
.hex-wrap svg g.concentration134 g { fill: #ffca2e}
.hex-wrap svg g.concentration135 g { fill: #ffcb2e}
.hex-wrap svg g.concentration136 g { fill: #ffcc2f}
.hex-wrap svg g.concentration137 g { fill: #ffcc2f}
.hex-wrap svg g.concentration138 g { fill: #ffcd2f}
.hex-wrap svg g.concentration139 g { fill: #ffce2f}
.hex-wrap svg g.concentration140 g { fill: #ffce2f}
.hex-wrap svg g.concentration141 g { fill: #ffcf2f}
.hex-wrap svg g.concentration142 g { fill: #ffcf30}
.hex-wrap svg g.concentration143 g { fill: #ffd030}
.hex-wrap svg g.concentration144 g { fill: #ffd130}
.hex-wrap svg g.concentration145 g { fill: #ffd130}
.hex-wrap svg g.concentration146 g { fill: #ffd230}
.hex-wrap svg g.concentration147 g { fill: #ffd331}
.hex-wrap svg g.concentration148 g { fill: #ffd331}
.hex-wrap svg g.concentration149 g { fill: #ffd431}
.hex-wrap svg g.concentration150 g { fill: #ffd531}
.hex-wrap svg g.concentration151 g { fill: #ffd532}
.hex-wrap svg g.concentration152 g { fill: #ffd632}
.hex-wrap svg g.concentration153 g { fill: #ffd732}
.hex-wrap svg g.concentration154 g { fill: #ffd732}
.hex-wrap svg g.concentration155 g { fill: #ffd833}
.hex-wrap svg g.concentration156 g { fill: #ffd933}
.hex-wrap svg g.concentration157 g { fill: #ffd933}
.hex-wrap svg g.concentration158 g { fill: #ffda34}
.hex-wrap svg g.concentration159 g { fill: #ffda34}
.hex-wrap svg g.concentration160 g { fill: #ffdb34}
.hex-wrap svg g.concentration161 g { fill: #ffdc34}
.hex-wrap svg g.concentration162 g { fill: #ffdc35}
.hex-wrap svg g.concentration163 g { fill: #ffdd35}
.hex-wrap svg g.concentration164 g { fill: #ffde35}
.hex-wrap svg g.concentration165 g { fill: #ffde36}
.hex-wrap svg g.concentration166 g { fill: #ffdf36}
.hex-wrap svg g.concentration167 g { fill: #ffe036}
.hex-wrap svg g.concentration168 g { fill: #ffe037}
.hex-wrap svg g.concentration169 g { fill: #ffe137}
.hex-wrap svg g.concentration170 g { fill: #ffe237}
.hex-wrap svg g.concentration171 g { fill: #ffe238}
.hex-wrap svg g.concentration172 g { fill: #ffe338}
.hex-wrap svg g.concentration173 g { fill: #ffe339}
.hex-wrap svg g.concentration174 g { fill: #ffe439}
.hex-wrap svg g.concentration175 g { fill: #ffe539}
.hex-wrap svg g.concentration176 g { fill: #ffe53a}
.hex-wrap svg g.concentration177 g { fill: #ffe63a}
.hex-wrap svg g.concentration178 g { fill: #ffe73b}
.hex-wrap svg g.concentration179 g { fill: #ffe73b}
.hex-wrap svg g.concentration180 g { fill: #ffe83b}
.hex-wrap svg g.concentration181 g { fill: #ffe93c}
.hex-wrap svg g.concentration182 g { fill: #fee93c}
.hex-wrap svg g.concentration183 g { fill: #feea3d}
.hex-wrap svg g.concentration184 g { fill: #feeb3d}
.hex-wrap svg g.concentration185 g { fill: #feeb3e}
.hex-wrap svg g.concentration186 g { fill: #feec3e}
.hex-wrap svg g.concentration187 g { fill: #fdec3f}
.hex-wrap svg g.concentration188 g { fill: #fded3f}
.hex-wrap svg g.concentration189 g { fill: #fdee3f}
.hex-wrap svg g.concentration190 g { fill: #fdee40}
.hex-wrap svg g.concentration191 g { fill: #fdef40}
.hex-wrap svg g.concentration192 g { fill: #fcf041}
.hex-wrap svg g.concentration193 g { fill: #fcf041}
.hex-wrap svg g.concentration194 g { fill: #fcf142}
.hex-wrap svg g.concentration195 g { fill: #fcf242}
.hex-wrap svg g.concentration196 g { fill: #fbf243}
.hex-wrap svg g.concentration197 g { fill: #fbf343}
.hex-wrap svg g.concentration198 g { fill: #fbf444}
.hex-wrap svg g.concentration199 g { fill: #fbf444}
.hex-wrap svg g.concentration200 g { fill: #faf545}
.hex-wrap svg g.concentration201 g { fill: #faf545}
.hex-wrap svg g.concentration202 g { fill: #faf646}
.hex-wrap svg g.concentration203 g { fill: #faf747}
.hex-wrap svg g.concentration204 g { fill: #f9f747}
.hex-wrap svg g.concentration205 g { fill: #f9f848}
.hex-wrap svg g.concentration206 g { fill: #f9f948}
.hex-wrap svg g.concentration207 g { fill: #f9f949}
.hex-wrap svg g.concentration208 g { fill: #f8fa49}
.hex-wrap svg g.concentration209 g { fill: #f8fb4a}
.hex-wrap svg g.concentration210 g { fill: #f8fb4a}
.hex-wrap svg g.concentration211 g { fill: #f7fc4b}
.hex-wrap svg g.concentration212 g { fill: #f7fc4c}
.hex-wrap svg g.concentration213 g { fill: #f7fd4c}
.hex-wrap svg g.concentration214 g { fill: #f7fe4d}
.hex-wrap svg g.concentration215 g { fill: #f6fe4d}
.hex-wrap svg g.concentration216 g { fill: #f6ff4e}



.hex-wrap svg g.brainactive0 g { fill: #f742ff}
.hex-wrap svg g.brainactive1 g { fill: #f742fe}
.hex-wrap svg g.brainactive2 g { fill: #f841fd}
.hex-wrap svg g.brainactive3 g { fill: #f841fd}
.hex-wrap svg g.brainactive4 g { fill: #f940fc}
.hex-wrap svg g.brainactive5 g { fill: #f940fb}
.hex-wrap svg g.brainactive6 g { fill: #fa3ffa}
.hex-wrap svg g.brainactive7 g { fill: #fa3ffa}
.hex-wrap svg g.brainactive8 g { fill: #fb3ef9}
.hex-wrap svg g.brainactive9 g { fill: #fb3ef8}
.hex-wrap svg g.brainactive10 g { fill: #fb3df7}
.hex-wrap svg g.brainactive11 g { fill: #fc3df6}
.hex-wrap svg g.brainactive12 g { fill: #fc3df6}
.hex-wrap svg g.brainactive13 g { fill: #fd3cf5}
.hex-wrap svg g.brainactive14 g { fill: #fd3cf4}
.hex-wrap svg g.brainactive15 g { fill: #fd3bf3}
.hex-wrap svg g.brainactive16 g { fill: #fe3bf3}
.hex-wrap svg g.brainactive17 g { fill: #fe3af2}
.hex-wrap svg g.brainactive18 g { fill: #ff3af1}
.hex-wrap svg g.brainactive19 g { fill: #ff3af0}
.hex-wrap svg g.brainactive20 g { fill: #ff39ef}
.hex-wrap svg g.brainactive21 g { fill: #ff39ef}
.hex-wrap svg g.brainactive22 g { fill: #ff38ee}
.hex-wrap svg g.brainactive23 g { fill: #ff38ed}
.hex-wrap svg g.brainactive24 g { fill: #ff37ec}
.hex-wrap svg g.brainactive25 g { fill: #ff37ec}
.hex-wrap svg g.brainactive26 g { fill: #ff37eb}
.hex-wrap svg g.brainactive27 g { fill: #ff36ea}
.hex-wrap svg g.brainactive28 g { fill: #ff36e9}
.hex-wrap svg g.brainactive29 g { fill: #ff35e9}
.hex-wrap svg g.brainactive30 g { fill: #ff35e8}
.hex-wrap svg g.brainactive31 g { fill: #ff35e7}
.hex-wrap svg g.brainactive32 g { fill: #ff34e6}
.hex-wrap svg g.brainactive33 g { fill: #ff34e5}
.hex-wrap svg g.brainactive34 g { fill: #ff34e5}
.hex-wrap svg g.brainactive35 g { fill: #ff33e4}
.hex-wrap svg g.brainactive36 g { fill: #ff33e3}
.hex-wrap svg g.brainactive37 g { fill: #ff32e2}
.hex-wrap svg g.brainactive38 g { fill: #ff32e2}
.hex-wrap svg g.brainactive39 g { fill: #ff32e1}
.hex-wrap svg g.brainactive40 g { fill: #ff31e0}
.hex-wrap svg g.brainactive41 g { fill: #ff31df}
.hex-wrap svg g.brainactive42 g { fill: #ff31df}
.hex-wrap svg g.brainactive43 g { fill: #ff30de}
.hex-wrap svg g.brainactive44 g { fill: #ff30dd}
.hex-wrap svg g.brainactive45 g { fill: #ff30dc}
.hex-wrap svg g.brainactive46 g { fill: #ff2fdc}
.hex-wrap svg g.brainactive47 g { fill: #ff2fdb}
.hex-wrap svg g.brainactive48 g { fill: #ff2fda}
.hex-wrap svg g.brainactive49 g { fill: #ff2ed9}
.hex-wrap svg g.brainactive50 g { fill: #ff2ed9}
.hex-wrap svg g.brainactive51 g { fill: #ff2ed8}
.hex-wrap svg g.brainactive52 g { fill: #ff2ed7}
.hex-wrap svg g.brainactive53 g { fill: #ff2dd6}
.hex-wrap svg g.brainactive54 g { fill: #ff2dd6}
.hex-wrap svg g.brainactive55 g { fill: #ff2dd5}
.hex-wrap svg g.brainactive56 g { fill: #ff2cd4}
.hex-wrap svg g.brainactive57 g { fill: #ff2cd4}
.hex-wrap svg g.brainactive58 g { fill: #ff2cd3}
.hex-wrap svg g.brainactive59 g { fill: #ff2cd2}
.hex-wrap svg g.brainactive60 g { fill: #ff2bd1}
.hex-wrap svg g.brainactive61 g { fill: #ff2bd1}
.hex-wrap svg g.brainactive62 g { fill: #ff2bd0}
.hex-wrap svg g.brainactive63 g { fill: #ff2bcf}
.hex-wrap svg g.brainactive64 g { fill: #ff2ace}
.hex-wrap svg g.brainactive65 g { fill: #ff2ace}
.hex-wrap svg g.brainactive66 g { fill: #ff2acd}
.hex-wrap svg g.brainactive67 g { fill: #ff2acc}
.hex-wrap svg g.brainactive68 g { fill: #ff2acc}
.hex-wrap svg g.brainactive69 g { fill: #ff29cb}
.hex-wrap svg g.brainactive70 g { fill: #ff29ca}
.hex-wrap svg g.brainactive71 g { fill: #ff29c9}
.hex-wrap svg g.brainactive72 g { fill: #ff29c9}
.hex-wrap svg g.brainactive73 g { fill: #ff29c8}
.hex-wrap svg g.brainactive74 g { fill: #ff29c7}
.hex-wrap svg g.brainactive75 g { fill: #ff29c6}
.hex-wrap svg g.brainactive76 g { fill: #ff28c6}
.hex-wrap svg g.brainactive77 g { fill: #ff28c5}
.hex-wrap svg g.brainactive78 g { fill: #ff28c4}
.hex-wrap svg g.brainactive79 g { fill: #ff28c4}
.hex-wrap svg g.brainactive80 g { fill: #ff28c3}
.hex-wrap svg g.brainactive81 g { fill: #ff28c2}
.hex-wrap svg g.brainactive82 g { fill: #ff28c2}
.hex-wrap svg g.brainactive83 g { fill: #ff28c1}
.hex-wrap svg g.brainactive84 g { fill: #ff28c0}
.hex-wrap svg g.brainactive85 g { fill: #ff28bf}
.hex-wrap svg g.brainactive86 g { fill: #ff28bf}
.hex-wrap svg g.brainactive87 g { fill: #ff27be}
.hex-wrap svg g.brainactive88 g { fill: #ff27bd}
.hex-wrap svg g.brainactive89 g { fill: #ff27bd}
.hex-wrap svg g.brainactive90 g { fill: #ff27bc}
.hex-wrap svg g.brainactive91 g { fill: #ff27bb}
.hex-wrap svg g.brainactive92 g { fill: #ff27bb}
.hex-wrap svg g.brainactive93 g { fill: #ff27ba}
.hex-wrap svg g.brainactive94 g { fill: #ff27b9}
.hex-wrap svg g.brainactive95 g { fill: #ff27b9}
.hex-wrap svg g.brainactive96 g { fill: #ff27b8}
.hex-wrap svg g.brainactive97 g { fill: #ff27b7}
.hex-wrap svg g.brainactive98 g { fill: #ff27b6}
.hex-wrap svg g.brainactive99 g { fill: #ff27b6}
.hex-wrap svg g.brainactive100 g { fill: #ff28b5}
.hex-wrap svg g.brainactive101 g { fill: #ff28b4}
.hex-wrap svg g.brainactive102 g { fill: #ff28b4}
.hex-wrap svg g.brainactive103 g { fill: #ff28b3}
.hex-wrap svg g.brainactive104 g { fill: #ff28b2}
.hex-wrap svg g.brainactive105 g { fill: #ff28b2}
.hex-wrap svg g.brainactive106 g { fill: #ff28b1}
.hex-wrap svg g.brainactive107 g { fill: #ff28b0}
.hex-wrap svg g.brainactive108 g { fill: #ff28b0}
.hex-wrap svg g.brainactive109 g { fill: #ff28af}
.hex-wrap svg g.brainactive110 g { fill: #ff28ae}
.hex-wrap svg g.brainactive111 g { fill: #ff28ae}
.hex-wrap svg g.brainactive112 g { fill: #ff29ad}
.hex-wrap svg g.brainactive113 g { fill: #ff29ac}
.hex-wrap svg g.brainactive114 g { fill: #ff29ac}
.hex-wrap svg g.brainactive115 g { fill: #ff29ab}
.hex-wrap svg g.brainactive116 g { fill: #ff29ab}
.hex-wrap svg g.brainactive117 g { fill: #ff29aa}
.hex-wrap svg g.brainactive118 g { fill: #ff2aa9}
.hex-wrap svg g.brainactive119 g { fill: #ff2aa9}
.hex-wrap svg g.brainactive120 g { fill: #ff2aa8}
.hex-wrap svg g.brainactive121 g { fill: #ff2aa7}
.hex-wrap svg g.brainactive122 g { fill: #ff2aa7}
.hex-wrap svg g.brainactive123 g { fill: #ff2aa6}
.hex-wrap svg g.brainactive124 g { fill: #ff2ba5}
.hex-wrap svg g.brainactive125 g { fill: #ff2ba5}
.hex-wrap svg g.brainactive126 g { fill: #ff2ba4}
.hex-wrap svg g.brainactive127 g { fill: #ff2ba4}
.hex-wrap svg g.brainactive128 g { fill: #ff2ba3}
.hex-wrap svg g.brainactive129 g { fill: #ff2ca2}
.hex-wrap svg g.brainactive130 g { fill: #ff2ca2}
.hex-wrap svg g.brainactive131 g { fill: #ff2ca1}
.hex-wrap svg g.brainactive132 g { fill: #ff2ca0}
.hex-wrap svg g.brainactive133 g { fill: #ff2da0}
.hex-wrap svg g.brainactive134 g { fill: #ff2d9f}
.hex-wrap svg g.brainactive135 g { fill: #ff2d9f}
.hex-wrap svg g.brainactive136 g { fill: #ff2d9e}
.hex-wrap svg g.brainactive137 g { fill: #ff2e9d}
.hex-wrap svg g.brainactive138 g { fill: #ff2e9d}
.hex-wrap svg g.brainactive139 g { fill: #ff2e9c}
.hex-wrap svg g.brainactive140 g { fill: #ff2e9b}
.hex-wrap svg g.brainactive141 g { fill: #ff2f9b}
.hex-wrap svg g.brainactive142 g { fill: #ff2f9a}
.hex-wrap svg g.brainactive143 g { fill: #ff2f9a}
.hex-wrap svg g.brainactive144 g { fill: #ff2f99}
.hex-wrap svg g.brainactive145 g { fill: #ff3098}
.hex-wrap svg g.brainactive146 g { fill: #ff3098}
.hex-wrap svg g.brainactive147 g { fill: #ff3097}
.hex-wrap svg g.brainactive148 g { fill: #ff3097}
.hex-wrap svg g.brainactive149 g { fill: #ff3196}
.hex-wrap svg g.brainactive150 g { fill: #ff3196}
.hex-wrap svg g.brainactive151 g { fill: #ff3195}
.hex-wrap svg g.brainactive152 g { fill: #ff3294}
.hex-wrap svg g.brainactive153 g { fill: #ff3294}
.hex-wrap svg g.brainactive154 g { fill: #ff3293}
.hex-wrap svg g.brainactive155 g { fill: #ff3293}
.hex-wrap svg g.brainactive156 g { fill: #ff3392}
.hex-wrap svg g.brainactive157 g { fill: #ff3391}
.hex-wrap svg g.brainactive158 g { fill: #ff3391}
.hex-wrap svg g.brainactive159 g { fill: #ff3490}
.hex-wrap svg g.brainactive160 g { fill: #ff3490}
.hex-wrap svg g.brainactive161 g { fill: #ff348f}
.hex-wrap svg g.brainactive162 g { fill: #ff358f}
.hex-wrap svg g.brainactive163 g { fill: #ff358e}
.hex-wrap svg g.brainactive164 g { fill: #ff358d}
.hex-wrap svg g.brainactive165 g { fill: #ff368d}
.hex-wrap svg g.brainactive166 g { fill: #ff368c}
.hex-wrap svg g.brainactive167 g { fill: #ff368c}
.hex-wrap svg g.brainactive168 g { fill: #ff368b}
.hex-wrap svg g.brainactive169 g { fill: #ff378b}
.hex-wrap svg g.brainactive170 g { fill: #ff378a}
.hex-wrap svg g.brainactive171 g { fill: #ff378a}
.hex-wrap svg g.brainactive172 g { fill: #ff3889}
.hex-wrap svg g.brainactive173 g { fill: #ff3889}
.hex-wrap svg g.brainactive174 g { fill: #ff3888}
.hex-wrap svg g.brainactive175 g { fill: #ff3987}
.hex-wrap svg g.brainactive176 g { fill: #ff3987}
.hex-wrap svg g.brainactive177 g { fill: #ff3986}
.hex-wrap svg g.brainactive178 g { fill: #ff3a86}
.hex-wrap svg g.brainactive179 g { fill: #ff3a85}
.hex-wrap svg g.brainactive180 g { fill: #ff3a85}
.hex-wrap svg g.brainactive181 g { fill: #ff3b84}
.hex-wrap svg g.brainactive182 g { fill: #ff3b84}
.hex-wrap svg g.brainactive183 g { fill: #ff3b83}
.hex-wrap svg g.brainactive184 g { fill: #ff3c83}
.hex-wrap svg g.brainactive185 g { fill: #ff3c82}
.hex-wrap svg g.brainactive186 g { fill: #ff3c82}
.hex-wrap svg g.brainactive187 g { fill: #ff3d81}
.hex-wrap svg g.brainactive188 g { fill: #ff3d81}
.hex-wrap svg g.brainactive189 g { fill: #ff3d80}
.hex-wrap svg g.brainactive190 g { fill: #ff3e80}
.hex-wrap svg g.brainactive191 g { fill: #ff3e7f}
.hex-wrap svg g.brainactive192 g { fill: #ff3e7f}
.hex-wrap svg g.brainactive193 g { fill: #ff3f7e}
.hex-wrap svg g.brainactive194 g { fill: #ff3f7e}
.hex-wrap svg g.brainactive195 g { fill: #ff3f7d}
.hex-wrap svg g.brainactive196 g { fill: #ff407d}
.hex-wrap svg g.brainactive197 g { fill: #ff407c}
.hex-wrap svg g.brainactive198 g { fill: #ff407c}
.hex-wrap svg g.brainactive199 g { fill: #ff417b}
.hex-wrap svg g.brainactive200 g { fill: #ff417b}
.hex-wrap svg g.brainactive201 g { fill: #ff417a}
.hex-wrap svg g.brainactive202 g { fill: #ff417a}
.hex-wrap svg g.brainactive203 g { fill: #ff4279}
.hex-wrap svg g.brainactive204 g { fill: #ff4279}
.hex-wrap svg g.brainactive205 g { fill: #ff4278}
.hex-wrap svg g.brainactive206 g { fill: #ff4378}
.hex-wrap svg g.brainactive207 g { fill: #ff4377}
.hex-wrap svg g.brainactive208 g { fill: #ff4377}
.hex-wrap svg g.brainactive209 g { fill: #ff4476}
.hex-wrap svg g.brainactive210 g { fill: #ff4476}
.hex-wrap svg g.brainactive211 g { fill: #ff4475}
.hex-wrap svg g.brainactive212 g { fill: #ff4575}
.hex-wrap svg g.brainactive213 g { fill: #ff4574}
.hex-wrap svg g.brainactive214 g { fill: #ff4574}
.hex-wrap svg g.brainactive215 g { fill: #ff4673}
.hex-wrap svg g.brainactive216 g { fill: #ff4673}
