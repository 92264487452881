@import url('https://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,700,700i,900,900i&display=swap&subset=latin-ext');
@import url('https://fonts.googleapis.com/earlyaccess/notosanskr.css');

/* Default Style */
html, body { width:100%; height:100%; }
html { overflow-y:scroll; font-size:18px;}
body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, form, fieldset, p, button { margin:0; padding:0; }
body, h1, h2, h3, h4, input, select, button, textarea { font-family:'Lato', 'Noto Sans KR', sans-serif;  color:#222; font-style:normal; font-weight:300;}
body { *word-break:break-all; -ms-word-break:break-all; }
img, fieldset, iframe { border:0 none; }
li { list-style:none; }
input, select, button { vertical-align:middle; }
img { vertical-align:top; }
i, em, address { font-style:normal; }
label, button { cursor:pointer; }
button { margin:0; padding:0; }
a { text-decoration:none; color:#222; }
a:hover { text-decoration:none; }
/*a:hover { text-decoration:underline; }*/
button * { position:relative; }
button img { left:-3px; *left:auto; }
option { padding-right:6px; }
caption { display:none; }
legend { *width:0 ;}
table {/* border-collapse:collapse;table-layout:fixed; */ border-collapse:collapse; border-spacing:0; }
textarea { resize:none; }
input[type=checkbox] { margin:0 5px 0 0; padding:0; *margin:-3px 0 0 -3px; vertical-align:-3px; *vertical-align:-3px; }
input[type=radio] { margin:0 5px 0 0; padding:0; *margin:-3px 0 0 -3px; vertical-align:-3px; *vertical-align:-3px; }
textarea { resize:none; }
::-webkit-input-placeholder { color:#ddd; }
:-moz-placeholder { color:#ddd; }
::-moz-placeholder { color:#ddd; }
:-ms-input-placeholder { color:#ddd; }
* {margin:0;padding:0;box-sizing:border-box;-webkit-text-size-adjust:none;}

input[type="text"], input[type="password"], input[type="number"], input[type="tel"], input[type="email"], input[type="button"], input[type="submit"], input[type="reset"] {height:45px; padding:0 ; border:solid 0 #ddd; border-radius:0;}
/*textarea {border:solid 1px #ddd}*/




