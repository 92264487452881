
.wrap-intro { opacity: 1}
.wrap-intro .play-intro { transition-duration: 0.5s; opacity: 1 }
.wrap-intro .hide-intro { transition-duration: 0.5s; opacity: 0 }

.wrap-stage { opacity: 0}
.wrap-stage .play-stage { transition-duration:0.5s; opacity: 1 }
.wrap-stage .hide-stage { transition-duration:0.5s; opacity: 0 }

.tabs { border-bottom: 2px solid #333333; }

.tabs .MuiTabs-indicator { display: none}
.tabs .tab {  padding-bottom: 0; font-size: 24px; font-family: 'Lato', 'Noto Sans KR', sans-serif; margin-bottom: 10px; letter-spacing: -2px}
.tabs .tab::after { content: '|'; padding-left: 25px; color: #E7E8E7; font-size: 20px}
.tabs .tab:last-child::after {content: ''}
.tabs .tab.Mui-selected {font-weight: bold; color: #0055BF}

.tabs.small { border-bottom: 2px solid #ddd;}
.tabs.small .tab { font-size: 18px }


.scorePoint {font-size: 32px; text-align: center; color: white; font-weight: bold; font-family: 'Lato'}
/** hexagon*/
.concentration { width: 150px; height: 150px}
.concentration .score { padding: 0 15px; font-size: 16px; max-width: 800px; margin: 0 auto; }
.concentration .score svg { width: 100%  }

.concentration .score svg text, 
.concentration .score p {
    pointer-events: none;
    text-shadow: 1px 1px 5px #000;
    text-anchor: middle;
    font-size: 120px;
    font-weight: bold;
    fill: rgba(255, 255, 255, 0.90);
    color: rgba(255, 255, 255, 0.90);
    alignment-baseline: middle;
    font-family: 'Lato', 'Noto Sans KR'
}

.concentration .score .row:nth-child(1n+2) {
    margin-top: -10.3%;
}

.concentration .score .row:nth-child(even) :first-child {
    margin-left: 16.666666667%;
}


.synapse .score { background: rgba(0,0,0, 0.5); color: #ddd; padding: 15px;  border-top-left-radius: 15px; border-bottom-left-radius: 15px; width: 130px; font-size: 24px; font-weight: bold}
.synapse .score .box { border-bottom: 1px solid #989EAB; padding-bottom: 10px; margin-bottom: 10px}
.synapse .score .box:last-child { border: 0; padding-bottom: 0; margin-bottom: 0}
.synapse .score .title { text-align: left; font-size: 16px; font-weight: normal}
.synapse .score .value { text-align: center; padding-top: 5px; font-size: 26px; color: white}
.synapse .score .small { font-weight: normal; font-size: 18px; padding-left: 5px}