
@media screen and (max-width: 1536px) {
    #header {zoom:0.8}
    #header.app { zoom: 1 !important;}
    
    #cont-wrap { zoom:0.8}
    #cont-wrap.ignore { zoom: 1}
    #cont-wrap.app { zoom: 1 !important;}

    .pop-over { transform: translate3d(-0px, 52px, 0px) !important;}
    .innerViewport { zoom: .8}
    .innerViewport.z7 { zoom: .7}
    
}

@media screen and (max-width: 1280px) {
    .sv div {position:relative;padding-left:40px; margin:0 auto;height:160px;}
}
