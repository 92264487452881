
.fft-chart-container {
    position: relative;
    /* max-width: 1280px; */
    width: 100%;
    height: 700px;
    overflow: hidden;
}

.fft-chart-container canvas {
    width: 100%;
    height: 100%;
    background: transparent;
}

.fft-chart-container .labels {
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    color: white;
}

.fft-chart-container .labels div {
    white-space: nowrap;
    position: absolute;
    left: 0;
    top: 0;
    color: #000;
    font-size: 14px;
}

.fft-chart-container .labels .mv-tooltip {
    white-space: nowrap;
    position: absolute;
    left: 0;
    top: 0;
    color: #fff;
    font-size: 22px;
    background-color: #000;
    /* padding:10px; */
    /* border-radius: 10px; */
    opacity: 0.8;
}

.fft-chart-container .arrows {
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    color: white;
}

.fft-chart-container .arrows div {
    white-space: nowrap;
    position: absolute;
    left: 0;
    top: 0;
    color: #000;
    font-size: 14px;
}

.fft-chart-container span {
    position: absolute;
    right: 0;
    bottom: 0;
}



/* .heatmap-grid  div { padding:0px !important } */
.radar {
    /*
    background: -webkit-radial-gradient(center, rgba(32, 255, 77, 0.3) 0%, rgba(32, 255, 77, 0) 75%), 
                -webkit-repeating-radial-gradient(rgba(32, 255, 77, 0) 5.8%, rgba(32, 255, 77, 0) 18%, rgba(32, 255, 77, 1) 18.6%, rgba(32, 255, 77, 0) 18.9%), 
                -webkit-linear-gradient(90deg, rgba(32, 255, 77, 0) 49.5%, rgba(32, 255, 77, 1) 50%, rgba(32, 255, 77, 1) 50%, rgba(32, 255, 77, 0) 50.2%), 
                -webkit-linear-gradient(0deg, rgba(32, 255, 77, 0) 49.5%, rgba(32, 255, 77, 1) 50%, rgba(32, 255, 77, 1) 50%, rgba(32, 255, 77, 0) 50.2%);
     */
    /*background: radial-gradient(center, rgba(32, 255, 77, 0.3) 0%, rgba(32, 255, 77, 0) 75%), repeating-radial-gradient(rgba(32, 255, 77, 0) 5.8%, rgba(32, 255, 77, 0) 18%, rgba(32, 255, 77, 1) 18.6%, rgba(32, 255, 77, 0) 18.9%), linear-gradient(90deg, rgba(32, 255, 77, 0) 49.5%, rgba(32, 255, 77, 1) 50%, rgba(32, 255, 77, 1) 50%, rgba(32, 255, 77, 0) 50.2%), linear-gradient(0deg, rgba(32, 255, 77, 0) 49.5%, rgba(32, 255, 77, 1) 50%, rgba(32, 255, 77, 1) 50%, rgba(32, 255, 77, 0) 50.2%);*/
    width: 100%;
    height: 100%;
    /*max-height: 75vh;*/
    /*max-width: 75vh;*/
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    /*border-radius: 50%;*/
    /*border: 0.2rem solid #20ff4d;*/
    overflow: hidden;
}
/*.radar:before {*/
/*    content: ' ';*/
/*    display: block;*/
/*    position: absolute;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    border-radius: 50%;*/
/*    !*animation: blips 5s infinite;*!*/
/*    animation-timing-function: linear;*/
/*    animation-delay: 1.4s;*/
/*}*/
.radar-beam {
    content: ' ';
    display: block;
    /*background-image: linear-gradient(0deg, rgba(0, 255, 51, 0) 0%, rgba(0, 255, 51, 1) 100%);*/
    /*background: rgba(0, 255, 51, 1);*/
    background: rgba(255, 0, 0, 1);
    width: 8px;
    height: calc(100% * 0.73);
    position: absolute;
    bottom: 0;
    left: calc(50% - 4px);
    animation-timing-function: linear;
    transform-origin: bottom right;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
 