
::-webkit-scrollbar { width: 0;}
::-webkit-scrollbar-track {  /*background: #f1f1f1;*/ }
::-webkit-scrollbar-thumb { background: #888; }
::-webkit-scrollbar-thumb:hover { background: #555; }
* {-ms-user-select: none; -moz-user-select: none; -webkit-user-select: none; user-select:none;}
*:focus {outline:none;}

@page {
    size: A4;
    margin: 0mm;
}

/* layout 공통 */
#root { height: 100%;}
#wrap { position:relative; height: 100%}
.MuiContainer-root { height: 100% }
.ratio-16-9 { aspect-ratio: 16 / 6; }
.text-left { text-align: left !important;}
.text-right { text-align: right !important;}
.text-white { color:white !important;}
.p-l-100 { padding-left: 100px}

#header { position:fixed; top:0; left:0; right:0;height:80px; border-bottom:1px solid rgba(255,255,255,0.2); z-index:11;  min-width:1280px;background:#fff;}
#header h1 { position:absolute; top:16px; left:30px; z-index:12;}
#header h1 a { display:block; width:180px; height:42px; text-indent:-9999px; background-size:100%;}
#header nav {display:block;}

#gnb { display:table;position:relative; margin:0 auto;}
#gnb>li {display:table-cell; padding:0 30px;text-align:center;letter-spacing:-0.5px;}
#gnb>li>a { display:block; line-height:80px; color:#222; font-weight:500;font-size:18px;}
#gnb>li:hover>a {color:#0055b8;}
/*#header .name {position:absolute; top:29px; right:30px;font-size:16px;}*/
#header .name {position:absolute; top:15px; right:30px;font-size:18px; font-weight: 500}

#cont-wrap {position:relative;margin-top:80px;min-width:1280px}

#footer {position:relative;background:#333;min-height:100px;}
#footer div {position:relative;width:1280px;margin:0 auto;}
#footer div p {color:#ddd;font-size:16px;padding-top:30px;}
#footer div .ct {position:absolute;right:0;top:30px;font-size:0;}
#footer div .ct li {position:relative; font-size:16px;display:inline-block;color:#ddd;}
#footer div .ct li + li {margin-left:20px;}
#footer div .ct li span {display:inline-block;padding-left:10px;}
#footer div .ct li span:before {content:'*';position:absolute;top:5px;left:0;}


#cont-wrap.app {min-width: auto; margin-top: 0}
#cont-wrap.app .sv { height: 120px }
#cont-wrap.app i { display: none }
#cont-wrap.app .sv div h3 { padding-top: 20px;  }
#cont-wrap.app .cont { width: 95%; margin: 2.5% auto }
#cont-wrap.app .ti-wrap { padding-left: 130px}
.app-back-profile button:hover { background-color: rgba(0,0,0,0.3)}
/*main :: visual */
.mv {position:relative;height:600px;overflow:hidden;background-position:50% 0; background-size:cover;background-repeat:no-repeat;}
/*.mv.bg01 {background-image:url(/user/assets/images/dashboard/mv_calm.jpg) ;} !*안정*!*/
/*.mv.bg02 {background-image:url(/user/assets/images/dashboard/mv_attention.jpg) ;} !*주의*!*/
/*.mv.bg03 {background-image:url(/user/assets/images/dashboard/mv_concentrate.jpg) ;} !*집중*!*/
/*.mv.bg04 {background-image:url(/user/assets/images/dashboard/mv_active.jpg) ;} !*활성*!*/
/*.mv.default {height:900px;background-image:url(/user/assets/images/dashboard/mv_default.jpg) ;}*/

.mv .innerbox {position:relative;width:1280px;margin:0 auto;height:100%;}
.mv .innerbox .date {position:absolute;left:0;bottom:70px;}
.mv .innerbox .date span {position:relative;display:block;color:#fff;font-size:30px;letter-spacing:-2px;line-height:1;padding-left:34px;}
.mv .innerbox .date span::before {position:absolute;left:0;top:2px;content:'';width:29px;height:28px;}
.mv .innerbox .date h3 {display:block;font-size:30px;font-weight:600;letter-spacing:-0.3px;line-height:1.8;color:#fff;border-bottom:3px solid rgba(255,255,255,0.8); }

.mv .innerbox .condition {position:absolute;right:0;top:0;width:640px;height:600px;}
.mv.bg01 .innerbox .condition {background: rgba(35,176,239,0.6);}
.mv.bg02 .innerbox .condition {background: rgba(108,175,111,0.6);}
.mv.bg03 .innerbox .condition {background: rgba(245,121,112,0.6);}
.mv.bg04 .innerbox .condition {background: rgba(188,122,205,0.6);}
.mv.default .innerbox .condition {position:absolute;left:0;top:280px;text-align:center;width:100%;}

.mv .innerbox .condition .inner {padding:110px 80px 0;}
.mv .innerbox .condition .inner h3 {display:block;position:relative;padding-left:96px;font-size:60px;color:#fff;font-weight:600;letter-spacing:-2px;line-height:1.5;}
.mv .innerbox .condition .inner h5 {display:block;position:relative;padding-left:96px;font-size:40px;color:#fff;font-weight:600;letter-spacing:-2px;line-height:1.5;}
.mv .innerbox .condition .inner h3::before {position:absolute;left:0;top:10px;content:'';width:85px;height:75px;}
.mv .innerbox .condition .inner p {font-weight:500;color:#fff;font-size:30px;letter-spacing:-2px;line-height:1.5;}
.mv .innerbox .condition .inner span {display:block;color:#fff;font-size:20px;letter-spacing:-0.5px;line-height:1.5;word-break: keep-all;}
/*.mv.bg01 .innerbox .condition .inner h3::before {background:url(/user/assets/images/dashboard/ico_calm.png);}*/
/*.mv.bg02 .innerbox .condition .inner h3::before {background:url(/user/assets/images/dashboard/ico_attention.png);}*/
/*.mv.bg03 .innerbox .condition .inner h3::before {background:url(/user/assets/images/dashboard/ico_concentrate.png);}*/
/*.mv.bg04 .innerbox .condition .inner h3::before {background:url(/user/assets/images/dashboard/ico_active.png);}*/

.mv .innerbox .condition .bt-start {display:block;position:absolute;bottom:70px;left:80px;border:3px solid rgba(255,255,255,0.8);padding:0 30px;border-radius:30px; cursor: pointer;transition-duration: 0.3s}
.mv .innerbox .condition .bt-start span {position:relative;display:inline-block;line-height:60px;color:#fff;font-size:20px;font-weight:500;padding-right:40px;}
.mv .innerbox .condition .bt-start span::after {position:absolute;right:0;top:22px;content:'';width:11px;height:17px;}
.mv .innerbox .condition .bt-start.ex-complete { border:0; padding-right: 0; } 

.mv.bg01 .innerbox .bt-start:hover {background: rgba(35,176,239,0.6);}
.mv.bg02 .innerbox .bt-start:hover {background: rgba(108,175,111,0.6);}
.mv.bg03 .innerbox .bt-start:hover {background: rgba(245,121,112,0.6);}
.mv.bg04 .innerbox .bt-start:hover {background: rgba(188,122,205,0.6);}

.mv.bg01 .innerbox .bt-start.ex-complete {background: rgba(35,176,239,0.6);}
.mv.bg02 .innerbox .bt-start.ex-complete {background: rgba(108,175,111,0.6);}
.mv.bg03 .innerbox .bt-start.ex-complete {background: rgba(245,121,112,0.6);}
.mv.bg04 .innerbox .bt-start.ex-complete {background: rgba(188,122,205,0.6);}


.mv.default .innerbox .condition .bt-start {bottom:320px;left:50%;margin-left:-105px;}


/*main :: 처방운동 */
.sec-presc {position:relative;background:#f8f8f8;box-sizing:border-box;}
.sec-presc .innerbox {position:relative;width:1280px;margin:0 auto;padding:80px 0;}
.sec-presc .innerbox h4 {display:block;position:relative;font-size:50px;font-weight:600;letter-spacing:-2px;text-align:center;line-height:120px;z-index:1;}
.sec-presc .innerbox h4 i {display:block;width:100%;text-align:center;position:absolute;top:-36px;left:50%;margin-left:-50%;font-size:72px;font-weight:600;color:#e0e5ef;z-index:-1;}
.sec-presc .innerbox .bt-more {position:absolute;right:0;top:125px;display:block;z-index:2;}
.sec-presc .innerbox .bt-more span {font-size:20px;color:#333;font-weight:400;padding-right:30px;line-height:1.2;}
.sec-presc .innerbox .bt-more span::after {position:absolute;right:0;top:2px;width:20px;height:20px;border:1px solid #333;border-radius:50%;content:'+';line-height:20px;text-align:center;font-size:22px;}
.sec-presc .innerbox ul {position:relative;font-size:0;margin:0 -40px;}
.sec-presc .innerbox ul li {position:relative;display:inline-block;font-size:20px;width:600px;border-bottom:1px solid #ddd;margin:0 40px;vertical-align:top;}
.sec-presc .innerbox ul li.empty { border:0px; text-align: center; width: 1280px;color: #999}
.sec-presc .innerbox ul li.empty h4 { font-size: 28px; margin:0; height: 90px;color: #999}
.sec-presc .innerbox ul li.empty img { width : 80px; margin-top: 50px}
.sec-presc .innerbox ul li dl {display:block;clear:both;padding:20px 0;}
.sec-presc .innerbox ul li dl dt {position:absolute;left:0;top:20px;width:90px;height:90px;border:1px solid #dddd;border-radius:50%;overflow:hidden;z-index:1;}
.sec-presc .innerbox ul li dl dt img {width:100%;}
.sec-presc .innerbox ul li dl dd {display:block;padding-left:110px;height:120px;font-size:18px;color:#666;line-height:1.3;word-break:keep-all;overflow:hidden;}
.sec-presc .innerbox ul li dl dd p {font-weight:500;font-size:24px;line-height:2;color:#333;}
.sec-presc .innerbox ul li dl dd p span {display:inline-block;background:#014fb4;border-radius:5px;color:#fff;font-size:18px;line-height:1.2;padding:5px 8px;margin-left:10px;}
.sec-presc .innerbox ul li .check {position:absolute;left:0;top:20px;width:90px;height:90px;border-radius:50%;z-index:2;background:rgb(255 235 59 / 37%);}
.sec-presc .innerbox ul li .check span {display:block;position:absolute;left:15px;top:2px;width:86px;height:71px;text-indent:-999999px;}
.sec-presc .innerbox ul li .log {padding-left:110px; margin-top: 10px; font-size:16px; font-weight: 500; color:#014fb4}

/*main :: 처방운동 */
.sec-presc .auto.innerbox {position:relative;margin:0 auto; padding:0; width: 100%}
.sec-presc .auto.innerbox ul {position:relative;font-size:0;margin:0;}
.sec-presc .auto.innerbox ul li {position:relative;display:inline-block;font-size:1rem;width:50%;border-bottom:1px solid #ddd;margin:0;vertical-align:top;}
.sec-presc .auto.innerbox ul li dl dt {position:absolute;left:20px;top:20px;width:64px;height:64px;border:1px solid #dddd;border-radius:50%;overflow:hidden;z-index:1;}
.sec-presc .auto.innerbox ul li dl dd {display:block;padding-left:110px;height:85px;font-size:18px;color:#666;line-height:1.3;word-break:keep-all;overflow:hidden;}
.sec-presc .auto.innerbox ul li dl dd p {font-weight:500;font-size:1rem;line-height:2;color:#333;}
.sec-presc .auto.innerbox ul li dl dd p span {display:inline-block;background:#014fb4;border-radius:5px;color:#fff;font-size:18px;line-height:1.2;padding:5px 8px;margin-left:10px;}
.sec-presc .auto.innerbox ul li .check {position:absolute;left:0;top:20px;width:64px;height:64px;border-radius:50%;z-index:2;background:rgb(255 235 59 / 37%);}


/*main :: 뇌기능측정 */
.sec-graph {position:relative;background:center 0/cover no-repeat;box-sizing:border-box;min-height:680px;}
.sec-graph .innerbox {position:relative;width:1280px;margin:0 auto;padding:80px 0;}
.sec-graph .innerbox h4 {display:block;position:relative;font-size:50px;font-weight:600;letter-spacing:-2px;text-align:center;line-height:120px;z-index:1;color:#fff;}
.sec-graph .innerbox h4 i {display:block;width:100%;text-align:center;position:absolute;top:-36px;left:50%;margin-left:-50%;font-size:72px;font-weight:600;color:#4871bb;z-index:-1;}
.sec-graph .innerbox .bt-more {position:absolute;right:0;top:125px;display:block;z-index:2;}
.sec-graph .innerbox .bt-more span {font-size:20px;color:#fff;font-weight:400;padding-right:30px;line-height:1.2;}
.sec-graph .innerbox .bt-more span::after {position:absolute;right:0;top:2px;width:20px;height:20px;border:1px solid #fff;border-radius:50%;content:'+';line-height:20px;text-align:center;font-size:22px;}
.sec-graph .innerbox .graph-box {position:relative;width:1000px;margin:0 auto;text-align:center;}
.sec-graph .innerbox .graph-box .gr-tit {position:relative;text-align:left;padding-bottom:30px;}
.sec-graph .innerbox .graph-box .gr-tit p {font-weight:500;font-size:26px;color:#fff;}
.sec-graph .innerbox .graph-box .gr-tit ul {position:absolute;right:0;top:0;display:block;font-size:0;}
.sec-graph .innerbox .graph-box .gr-tit ul li {position:relative;display:inline-block;font-size:18px;line-height:1;color:#fff;}
.sec-graph .innerbox .graph-box .gr-tit ul li + li {margin-left:30px;}
.sec-graph .innerbox .graph-box .gr-tit ul li span {display:block;padding-left:30px;}
.sec-graph .innerbox .graph-box .gr-tit ul li span::before {position:absolute;left:0;top:0;width:17px;height:17px;content:'';}
.sec-graph .innerbox .graph-box .gr-tit ul li.now span::before {border:2px solid #00b0f5;background:rgb(0 176 245 / 50%);}
.sec-graph .innerbox .graph-box .gr-tit ul li.pre span::before {border:2px solid #d599df;background:rgb(213 153 223 / 50%);}

/*main :: 뇌의식패턴 & 자각증상 */
.sec-bpa {position:relative;box-sizing:border-box;min-height:540px;color:#222;}
.sec-bpa .innerbox {position:relative;width:1280px;margin:80px auto;}
.sec-bpa .innerbox h4 {display:block;font-size:36px;font-weight:600;letter-spacing:-2px;line-height:1;padding-bottom:40px;}
.sec-bpa .innerbox .l-pattern {position:absolute;left:0;top:0;width:560px;}
.sec-bpa .innerbox .l-pattern .pattern {position:relative;}
.sec-bpa .innerbox .r-awakening {position:absolute;right:0;top:0;width:640px;}
.sec-bpa .innerbox .r-awakening .bt-more {position:absolute;right:0;top:5px;display:block;z-index:2;}
.sec-bpa .innerbox .r-awakening .bt-more span {font-size:20px;color:#333;font-weight:400;padding-right:30px;line-height:1.2;}
.sec-bpa .innerbox .r-awakening .bt-more span::after {position:absolute;right:0;top:2px;width:20px;height:20px;border:1px solid #333;border-radius:50%;content:'+';line-height:20px;text-align:center;font-size:22px;}
.sec-bpa .innerbox .r-awakening .awakening {position:relative;font-size:24px;}


/* sub layout*/
.sv {position:relative;height:160px;background:#0156b8;overflow:hidden;}
.sv.white {background-color: white;}
.sv.white h3 {color: #333}
.sv.green { background-color: #247C40 !important;}
.sv div {position:relative;width:1280px;margin:0 auto;height:160px;}
.sv div h3 {display:block;padding:40px 0 0;font-size:30px;font-weight:600;letter-spacing:-2px;line-height:1.5;color:#fff;}
.sv div span {display:block;font-size:20px;letter-spacing:-1px;color:rgba(255,255,255,0.8);}
.sv div i{position:absolute;display:block;right:0;bottom:-10px;width:640px;text-align:right;font-size:72px;line-height:1;letter-spacing:-2px;font-weight:600;color:rgba(255,255,255,0.1);}
.cont {position:relative;width:1280px; margin:60px auto;min-height:500px;}



/* common pop */
.common-dialog-title .MuiTypography-root { font-family: 'Noto Sans KR'; font-weight: bold; color: #0060C0; font-size: 36px; letter-spacing: -2px}
.common-dialog-content { font-family: 'Noto Sans KR'; min-width: 550px; text-align: center; font-size: 21px; margin: 40px 0 }
.common-dialog-content audio { width: 80% }
.common-dialog-action.MuiDialogActions-root { background-color: #0060C0; color: #fff; min-height: 80px}
.common-dialog-action .MuiButton-label { color: #fff; font-size: 26px; font-weight: bold }
.common-dialog-close { margin-top: 10px}
.common-dialog-close .MuiSvgIcon-root { font-size: 35px}

.common-top-dialog-title .MuiTypography-root { font-family: 'Noto Sans KR'; letter-spacing: -2px; color: #0060C0;}
.common-top-dialog-content { font-family: 'Noto Sans KR'; min-width: 550px; text-align: left; font-size: 21px; padding: 40px 20px !important; }
.common-top-dialog-submit.MuiButton-containedPrimary { background-color: #0156B8}
.common-top-dialog-submit.MuiButton-containedPrimary:hover { background-color: #063387 }

/* common material button */
.common-sm-button { border: 1px solid #BEBEBE }
.common-button {border: 1px solid #BEBEBE !important; padding: 5px 30px !important; border-radius: 20px !important; color: #666 !important; background-color: #fff}
.common-button .MuiButton-endIcon { margin-right: -15px !important;}
.common-button .rightIcon {color: #00DD94; font-size: 25px !important; font-weight: bold !important;}

.common-button.blue { background-color: #0156b8; border: 2px solid #0156b8 !important;}
.common-button.blue:hover {background-color: #073A8F; }
.common-button.blue .MuiButton-label { color: #fff; }

/* monitor */
.monitor-dialog-content { font-family: 'Noto Sans KR'; min-width: 1024px; }

/* measure */


.sc-measure {position:relative;max-width: 800px; margin: 0 auto }
.sc-measure ul {position:relative;font-size:0;}
.sc-measure ul li {border-bottom:0.1px solid #dcdcdc;padding-bottom:3rem;margin-bottom:3rem;}
.sc-measure ul li:last-child {margin-bottom:0;}
.sc-measure ul li dl dt {font-size:1.5rem;line-height:1.5;font-weight:500;letter-spacing:-0.1rem;padding-bottom:1rem;}
.sc-measure ul li dl dd ul {text-align:center;}

.sc-measure ul li dl dd ul li {width:calc(10% - 0.2rem);margin:0 0.2rem 0 0;display:inline-block;font-size:1rem;color:#333;font-weight:400;line-height:1.8;border-bottom:none;padding:0;}
.sc-measure ul li dl dd ul li:first-child {margin-left:0;}
.sc-measure ul li dl dd ul li div {width:3.6rem;height:3.6rem;border-radius:50%;margin:0 auto; cursor: pointer}
.sc-measure ul li dl dd ul li:nth-child(1) div {border:1px solid #ccddf1;background:#e5eef8; transition-duration: 0.3s}
.sc-measure ul li dl dd ul li:nth-child(1) div:hover {border:1px solid #e5eef8;background:#e5eef8; transition-duration: 0.3s}
.sc-measure ul li dl dd ul li:nth-child(2) div {border:1px solid #ccddf1;background:#ccddf1; transition-duration: 0.3s}
.sc-measure ul li dl dd ul li:nth-child(2) div:hover  {border:1px solid #ccddf1;background:#ccddf1; transition-duration: 0.3s}
.sc-measure ul li dl dd ul li:nth-child(3) div {border:1px solid #b2ccea;background:#b2ccea; transition-duration: 0.3s}
.sc-measure ul li dl dd ul li:nth-child(3) div:hover  {border:1px solid #b2ccea;background:#b2ccea; transition-duration: 0.3s}
.sc-measure ul li dl dd ul li:nth-child(4) div {border:1px solid #99bbe3;background:#99bbe3; transition-duration: 0.3s}
.sc-measure ul li dl dd ul li:nth-child(4) div:hover  {border:1px solid #99bbe3;background:#99bbe3; transition-duration: 0.3s}
.sc-measure ul li dl dd ul li:nth-child(5) div {border:1px solid #80aadb;background:#80aadb; transition-duration: 0.3s}
.sc-measure ul li dl dd ul li:nth-child(5) div:hover  {border:1px solid #80aadb;background:#80aadb; transition-duration: 0.3s}
.sc-measure ul li dl dd ul li:nth-child(6) div {border:1px solid #679ad4;background:#679ad4; transition-duration: 0.3s}
.sc-measure ul li dl dd ul li:nth-child(6) div:hover  {border:1px solid #679ad4;background:#679ad4; transition-duration: 0.3s}
.sc-measure ul li dl dd ul li:nth-child(7) div {border:1px solid #4d88cd;background:#4d88cd; transition-duration: 0.3s}
.sc-measure ul li dl dd ul li:nth-child(7) div:hover  {border:1px solid #4d88cd;background:#4d88cd; transition-duration: 0.3s}
.sc-measure ul li dl dd ul li:nth-child(8) div {border:1px solid #3478c6;background:#3478c6; transition-duration: 0.3s}
.sc-measure ul li dl dd ul li:nth-child(8) div:hover  {border:1px solid #3478c6;background:#3478c6; transition-duration: 0.3s}
.sc-measure ul li dl dd ul li:nth-child(9) div {border:1px solid #1a67bf;background:#1a67bf; transition-duration: 0.3s}
.sc-measure ul li dl dd ul li:nth-child(9) div:hover  {border:1px solid #1a67bf;background:#1a67bf; transition-duration: 0.3s}
.sc-measure ul li dl dd ul li:nth-child(10) div {border:1px solid #0156b8;background:#0156b8; transition-duration: 0.3s}
.sc-measure ul li dl dd ul li:nth-child(10) div:hover  {border:1px solid #0156b8;background:#0156b8; transition-duration: 0.3s}

.sc-measure.life-cycle ul li dl dd ul li div {width:3.6rem;height:3.6rem;border-radius:10px;margin:0 auto; cursor: pointer}
.sc-measure.life-cycle ul li dl dd ul li {width:calc(9% - 0.2rem);margin:0 0.2rem 0 0;display:inline-block;font-size:1rem;color:#8e8786;font-weight:400;line-height:1.8;border-bottom:none;padding:0;}
.sc-measure.life-cycle ul li dl dd ul li:nth-child(6) div {border:1px solid #ccddf1;background:#fff; transition-duration: 0.3s}

.sc-measure.life-cycle ul li dl dd ul li:nth-child(1) div {border:1px solid #0156b8;background:#0156b8; transition-duration: 0.3s}
.sc-measure.life-cycle ul li dl dd ul li:nth-child(1) div:hover  {border:1px solid #0156b8;background:#0156b8; transition-duration: 0.3s}
.sc-measure.life-cycle ul li dl dd ul li:nth-child(2) div {border:1px solid #3478c6;background:#3478c6; transition-duration: 0.3s}
.sc-measure.life-cycle ul li dl dd ul li:nth-child(2) div:hover  {border:1px solid #3478c6;background:#3478c6; transition-duration: 0.3s}
.sc-measure.life-cycle ul li dl dd ul li:nth-child(3) div {border:1px solid #679ad4;background:#679ad4; transition-duration: 0.3s}
.sc-measure.life-cycle ul li dl dd ul li:nth-child(3) div:hover  {border:1px solid #679ad4;background:#679ad4; transition-duration: 0.3s}
.sc-measure.life-cycle ul li dl dd ul li:nth-child(4) div {border:1px solid #99bbe3;background:#99bbe3; transition-duration: 0.3s}
.sc-measure.life-cycle ul li dl dd ul li:nth-child(4) div:hover  {border:1px solid #99bbe3;background:#99bbe3; transition-duration: 0.3s}
.sc-measure.life-cycle ul li dl dd ul li:nth-child(5) div {border:1px solid #ccddf1;background:#ccddf1; transition-duration: 0.3s}
.sc-measure.life-cycle ul li dl dd ul li:nth-child(5) div:hover  {border:1px solid #ccddf1;background:#ccddf1; transition-duration: 0.3s}

.sc-measure.life-cycle ul li dl dd ul li:nth-child(11) div {border:1px solid #0156b8;background:#0156b8; transition-duration: 0.3s}
.sc-measure.life-cycle ul li dl dd ul li:nth-child(11) div:hover  {border:1px solid #0156b8;background:#0156b8; transition-duration: 0.3s}
.sc-measure.life-cycle ul li dl dd ul li:nth-child(10) div {border:1px solid #3478c6;background:#3478c6; transition-duration: 0.3s}
.sc-measure.life-cycle ul li dl dd ul li:nth-child(10) div:hover  {border:1px solid #3478c6;background:#3478c6; transition-duration: 0.3s}
.sc-measure.life-cycle ul li dl dd ul li:nth-child(9) div {border:1px solid #679ad4;background:#679ad4; transition-duration: 0.3s}
.sc-measure.life-cycle ul li dl dd ul li:nth-child(9) div:hover  {border:1px solid #679ad4;background:#679ad4; transition-duration: 0.3s}
.sc-measure.life-cycle ul li dl dd ul li:nth-child(8) div {border:1px solid #99bbe3;background:#99bbe3; transition-duration: 0.3s}
.sc-measure.life-cycle ul li dl dd ul li:nth-child(8) div:hover  {border:1px solid #99bbe3;background:#99bbe3; transition-duration: 0.3s}
.sc-measure.life-cycle ul li dl dd ul li:nth-child(7) div {border:1px solid #ccddf1;background:#ccddf1; transition-duration: 0.3s}
.sc-measure.life-cycle ul li dl dd ul li:nth-child(7) div:hover  {border:1px solid #ccddf1;background:#ccddf1; transition-duration: 0.3s}


/*.sc-measure.life ul li dl dd ul li div {width:3.6rem;height:3.6rem;border-radius:50%;margin:0 auto; cursor: pointer}*/
/*.sc-measure.life ul li dl dd ul li {width:calc(9% - 0.2rem);margin:0 0.2rem 0 0;display:inline-block;font-size:1rem;color:#8e8786;font-weight:400;line-height:1.8;border-bottom:none;padding:0;}*/
/*.sc-measure.life ul li dl dd ul li:nth-child(6) div {border:1px solid #ccddf1;background:#fff; transition-duration: 0.3s}*/

/*.sc-measure.life ul li dl dd ul li:nth-child(1) div {border:1px solid #0156b8;background:#0156b8; transition-duration: 0.3s}*/
/*.sc-measure.life ul li dl dd ul li:nth-child(1) div:hover  {border:1px solid #0156b8;background:#0156b8; transition-duration: 0.3s}*/
/*.sc-measure.life ul li dl dd ul li:nth-child(2) div {border:1px solid #3478c6;background:#3478c6; transition-duration: 0.3s}*/
/*.sc-measure.life ul li dl dd ul li:nth-child(2) div:hover  {border:1px solid #3478c6;background:#3478c6; transition-duration: 0.3s}*/
/*.sc-measure.life ul li dl dd ul li:nth-child(3) div {border:1px solid #679ad4;background:#679ad4; transition-duration: 0.3s}*/
/*.sc-measure.life ul li dl dd ul li:nth-child(3) div:hover  {border:1px solid #679ad4;background:#679ad4; transition-duration: 0.3s}*/
/*.sc-measure.life ul li dl dd ul li:nth-child(4) div {border:1px solid #99bbe3;background:#99bbe3; transition-duration: 0.3s}*/
/*.sc-measure.life ul li dl dd ul li:nth-child(4) div:hover  {border:1px solid #99bbe3;background:#99bbe3; transition-duration: 0.3s}*/
/*.sc-measure.life ul li dl dd ul li:nth-child(5) div {border:1px solid #ccddf1;background:#ccddf1; transition-duration: 0.3s}*/
/*.sc-measure.life ul li dl dd ul li:nth-child(5) div:hover  {border:1px solid #ccddf1;background:#ccddf1; transition-duration: 0.3s}*/

/*.sc-measure.life ul li dl dd ul li:nth-child(11) div {border:1px solid #0156b8;background:#0156b8; transition-duration: 0.3s}*/
/*.sc-measure.life ul li dl dd ul li:nth-child(11) div:hover  {border:1px solid #0156b8;background:#0156b8; transition-duration: 0.3s}*/
/*.sc-measure.life ul li dl dd ul li:nth-child(10) div {border:1px solid #3478c6;background:#3478c6; transition-duration: 0.3s}*/
/*.sc-measure.life ul li dl dd ul li:nth-child(10) div:hover  {border:1px solid #3478c6;background:#3478c6; transition-duration: 0.3s}*/
/*.sc-measure.life ul li dl dd ul li:nth-child(9) div {border:1px solid #679ad4;background:#679ad4; transition-duration: 0.3s}*/
/*.sc-measure.life ul li dl dd ul li:nth-child(9) div:hover  {border:1px solid #679ad4;background:#679ad4; transition-duration: 0.3s}*/
/*.sc-measure.life ul li dl dd ul li:nth-child(8) div {border:1px solid #99bbe3;background:#99bbe3; transition-duration: 0.3s}*/
/*.sc-measure.life ul li dl dd ul li:nth-child(8) div:hover  {border:1px solid #99bbe3;background:#99bbe3; transition-duration: 0.3s}*/
/*.sc-measure.life ul li dl dd ul li:nth-child(7) div {border:1px solid #ccddf1;background:#ccddf1; transition-duration: 0.3s}*/
/*.sc-measure.life ul li dl dd ul li:nth-child(7) div:hover  {border:1px solid #ccddf1;background:#ccddf1; transition-duration: 0.3s}*/


.sc-measure.life ul li dl dd ul li:nth-child(10) div {border:1px solid #ccddf1;background:#e5eef8; transition-duration: 0.3s}
.sc-measure.life ul li dl dd ul li:nth-child(10) div:hover {border:1px solid #e5eef8;background:#e5eef8; transition-duration: 0.3s}
.sc-measure.life ul li dl dd ul li:nth-child(9) div {border:1px solid #ccddf1;background:#ccddf1; transition-duration: 0.3s}
.sc-measure.life ul li dl dd ul li:nth-child(9) div:hover  {border:1px solid #ccddf1;background:#ccddf1; transition-duration: 0.3s}
.sc-measure.life ul li dl dd ul li:nth-child(8) div {border:1px solid #b2ccea;background:#b2ccea; transition-duration: 0.3s}
.sc-measure.life ul li dl dd ul li:nth-child(8) div:hover  {border:1px solid #b2ccea;background:#b2ccea; transition-duration: 0.3s}
.sc-measure.life ul li dl dd ul li:nth-child(7) div {border:1px solid #99bbe3;background:#99bbe3; transition-duration: 0.3s}
.sc-measure.life ul li dl dd ul li:nth-child(7) div:hover  {border:1px solid #99bbe3;background:#99bbe3; transition-duration: 0.3s}
.sc-measure.life ul li dl dd ul li:nth-child(6) div {border:1px solid #80aadb;background:#80aadb; transition-duration: 0.3s}
.sc-measure.life ul li dl dd ul li:nth-child(6) div:hover  {border:1px solid #80aadb;background:#80aadb; transition-duration: 0.3s}
.sc-measure.life ul li dl dd ul li:nth-child(5) div {border:1px solid #679ad4;background:#679ad4; transition-duration: 0.3s}
.sc-measure.life ul li dl dd ul li:nth-child(5) div:hover  {border:1px solid #679ad4;background:#679ad4; transition-duration: 0.3s}
.sc-measure.life ul li dl dd ul li:nth-child(4) div {border:1px solid #4d88cd;background:#4d88cd; transition-duration: 0.3s}
.sc-measure.life ul li dl dd ul li:nth-child(4) div:hover  {border:1px solid #4d88cd;background:#4d88cd; transition-duration: 0.3s}
.sc-measure.life ul li dl dd ul li:nth-child(3) div {border:1px solid #3478c6;background:#3478c6; transition-duration: 0.3s}
.sc-measure.life ul li dl dd ul li:nth-child(3) div:hover  {border:1px solid #3478c6;background:#3478c6; transition-duration: 0.3s}
.sc-measure.life ul li dl dd ul li:nth-child(2) div {border:1px solid #1a67bf;background:#1a67bf; transition-duration: 0.3s}
.sc-measure.life ul li dl dd ul li:nth-child(2) div:hover  {border:1px solid #1a67bf;background:#1a67bf; transition-duration: 0.3s}
.sc-measure.life ul li dl dd ul li:nth-child(1) div {border:1px solid #0156b8;background:#0156b8; transition-duration: 0.3s}
.sc-measure.life ul li dl dd ul li:nth-child(1) div:hover  {border:1px solid #0156b8;background:#0156b8; transition-duration: 0.3s}


.sc-measure.recognition { max-width:  1024px}
.sc-measure.recognition dt { width: 60%; display: inline-block; position: relative; margin-right: 5%}
.sc-measure.recognition dt div { position: absolute; top: -50px}
.sc-measure.recognition dd { width: 35%; display: inline-block}
.sc-measure.recognition dd ul li { width: 30%;}
.sc-measure.recognition dd ul li div { background-color: #fff !important; border: 1px solid #0356B8 !important;}
.sc-measure.recognition dd ul li.checked div { background-color: #4D88CD !important;}

.sc-measure ul li dl dd ul li div.bgn { background-color: #fff !important;}

.level-dialog.width-810 {width: 810px !important;}
.level-dialog #responsive-dialog-title { border-bottom: 1px solid #EEEDEF; padding-bottom: 20px; border-top:5px solid #3F51B5}
.level-dialog #responsive-dialog-title.print { border-bottom:5px solid #3F51B5}
.level-dialog .print-bottom { border-top:5px solid #3F51B5; text-align: center; padding-top: 10px; font-weight: bold; font-size: 1.3rem}
.level-dialog #responsive-dialog-title h2 { color: #120075; text-align: center; font-size: 1.5rem;font-weight: bold;}
.level-dialog .title { font-size: 1.3rem; font-weight: bold; text-align: center; padding:20px 20px 10px 20px; min-height:76px;}
.level-dialog .title.point { text-align: left;}
.level-dialog .title.point:before { content:'■'; font-size: 1.7rem; padding-right: 6px;}
.level-dialog .box { background-color: #F6F9FC; padding:30px;}
.level-dialog .box li:before { content: '-'; padding-right: 4px; }
.level-dialog .box li { margin-bottom: 4px; font-weight: 400;  font-size: 0.9rem}
@media (max-width: 1344px) {
    .level-dialog .box li { font-size: 0.79rem}
}
.level-dialog .time-table { text-align: center}
.level-dialog .time-table .sub-title { font-size:0.9rem; font-weight: bold; border-bottom: 1px solid #ddd; padding-bottom: 10px; margin-bottom: 10px}
.level-dialog .time-table .MuiGrid-item:first-child  { border-right: 1px solid #EEEDEF}

.level-dialog .result-wrap { background-color: #F6F9FC }
.level-dialog .result-box { border: 1px solid #100070; text-align:center; display: flex; flex-direction: column; justify-content: center }
.level-dialog .result-box p { font-weight: bold ; font-size: 1.1rem; border-bottom: 1px solid #100070; padding: 10px 0 }
.level-dialog .result-box p.text-blue { color: #100070}
.level-dialog .result-box .score { border:0; font-weight: bold ; font-size: 1.3rem; padding: 20px 0; background-color: #EDF4FA; line-height: 30px; border-radius: 4px}

.level-dialog .flex { display: flex }
.level-dialog .inline { display: inline }
.level-dialog .inline { margin-top: 45px}
.level-dialog .left-box-title { writing-mode: vertical-rl; padding-right:12px; letter-spacing: 5px; background-color: #033E83; color: white;  border-radius: 4px;text-align: center;font-size: 1rem;font-weight: bold; width: 60px; margin-right: 20px; margin-left:30px; min-height: 256px}
.level-dialog .left-box-title-land {  background-color: #033E83; color: white;  border-radius: 4px; font-size: 1.1rem;font-weight: bold; width: 180px; margin-right: 20px; margin-left:30px; margin-bottom: 20px; text-align: left; padding: 10px; padding-left: 20px }
.level-dialog .left-box-title-land.mt45 { margin-top: 45px}
.level-dialog .result-each-box { max-width: 600px !important; padding-left: 30px}

.level-dialog table thead { border-bottom: 1px solid #000}
.level-dialog table th { text-align: center; font-weight: bold; color: #100070; background: #F6F9FC; border-top: 1px solid #100070; border-bottom: 1px solid #100070; font-size: 1.1rem; padding: 14px }
.level-dialog table td { text-align: center }

.level-dialog.develop,.level-dialog.train #responsive-dialog-title {border-top:5px solid #247C40 !important;}
.level-dialog.develop,.level-dialog.train #responsive-dialog-title h2 {color: #247C40 !important;}
.level-dialog.develop,.level-dialog.train .MuiButton-containedPrimary {background-color: #247C40 !important;}
.level-dialog.develop,.level-dialog.train .result-box .score { background-color: #F1FAF2 !important;}

/*.level-dialog.develop,.level-dialog.train .MuiButtonGroup-root button {border-color:#247C40 !important; color:  #247C40 !important;}*/
.level-dialog.develop,.level-dialog.train .left-box-title-land { background-color: #247C40 !important}
.level-dialog.develop,.level-dialog.train .left-box-title { background-color: #247C40 !important}

.level-dialog.develop.test-2-color #responsive-dialog-title {border-top:5px solid #25A2A4 !important;}
.level-dialog.develop.test-2-color #responsive-dialog-title h2 {color: #25A2A4 !important;}
.level-dialog.develop.test-2-color .MuiButton-containedPrimary {background-color: #25A2A4 !important;}
.level-dialog.develop.test-2-color .result-box .score { background-color: #EFFAFB !important;}
.level-dialog.develop.test-2-color .left-box-title-land { background-color: #25A2A4 !important}
.level-dialog.develop.test-2-color .left-box-title { background-color: #25A2A4 !important}

.level-dialog.develop.test-3-color #responsive-dialog-title {border-top:5px solid #035031 !important;}
.level-dialog.develop.test-3-color #responsive-dialog-title h2 {color: #035031 !important;}
.level-dialog.develop.test-3-color .MuiButton-containedPrimary {background-color: #035031 !important;}
.level-dialog.develop.test-3-color .result-box .score { background-color: #F1FBF8 !important;}
.level-dialog.develop.test-3-color .left-box-title-land { background-color: #035031 !important}
.level-dialog.develop.test-3-color .left-box-title { background-color: #035031 !important}

.level-dialog.develop.test-32-color #responsive-dialog-title {border-top:5px solid #E67398 !important;}
.level-dialog.develop.test-32-color #responsive-dialog-title h2 {color: #E67398 !important;}
.level-dialog.develop.test-32-color .MuiButton-containedPrimary {background-color: #E67398 !important;}
.level-dialog.develop.test-32-color .result-box .score { background-color: #FFF1F6 !important;}
.level-dialog.develop.test-32-color .left-box-title-land { background-color: #E67398 !important}
.level-dialog.develop.test-32-color .left-box-title { background-color: #E67398 !important}

.level-dialog.develop.test-33-color #responsive-dialog-title {border-top:5px solid #552342 !important;}
.level-dialog.develop.test-33-color #responsive-dialog-title h2 {color: #552342 !important;}
.level-dialog.develop.test-33-color .MuiButton-containedPrimary {background-color: #552342 !important;}
.level-dialog.develop.test-33-color .result-box .score { background-color: #FFF1F6 !important;}
.level-dialog.develop.test-33-color .left-box-title-land { background-color: #552342 !important}
.level-dialog.develop.test-33-color .left-box-title { background-color: #552342 !important}

.level-dialog.develop.test-4-color #responsive-dialog-title {border-top:5px solid #3E550C !important;}
.level-dialog.develop.test-4-color #responsive-dialog-title h2 {color: #3E550C !important;}
.level-dialog.develop.test-4-color .MuiButton-containedPrimary {background-color: #3E550C !important;}
.level-dialog.develop.test-4-color .result-box .score { background-color: #F1FAF2 !important;}
.level-dialog.develop.test-4-color .left-box-title-land { background-color: #3E550C !important}
.level-dialog.develop.test-4-color .left-box-title { background-color: #3E550C !important}

.level-dialog.develop.test-5-color #responsive-dialog-title {border-top:5px solid #257D41 !important;}
.level-dialog.develop.test-5-color #responsive-dialog-title h2 {color: #257D41 !important;}
.level-dialog.develop.test-5-color .MuiButton-containedPrimary {background-color: #257D41 !important;}
.level-dialog.develop.test-5-color .result-box .score { background-color: #F1FAF2 !important;}
.level-dialog.develop.test-6-color .left-box-title-land { background-color: #257D41 !important}
.level-dialog.develop.test-6-color .left-box-title { background-color: #257D41 !important}


.level-dialog.develop.test-6-color #responsive-dialog-title {border-top:5px solid #42279B !important;}
.level-dialog.develop.test-6-color #responsive-dialog-title h2 {color: #42279B !important;}
.level-dialog.develop.test-6-color .MuiButton-containedPrimary {background-color: #42279B !important;}
.level-dialog.develop.test-6-color .result-box .score { background-color: #F3F1FE !important;}
.level-dialog.develop.test-6-color .left-box-title-land { background-color: #42279B !important}
.level-dialog.develop.test-6-color .left-box-title { background-color: #42279B !important}

.level-dialog .measure-level-dialog {background-color: #0156B8;color: white;text-align: center; font-weight: bold; font-size: 1.2rem; line-height: 48px; min-height: 48px; cursor: pointer}
.level-dialog .measure-level-dialog.develop {background-color: #247C40}
.level-dialog .measure-level-dialog.develop.level-active { background-color: #32AE59}
.level-dialog .measure-level-dialog.develop.level-def { background-color: #FFF; color: #000; border: 2px solid #ddd}
.level-dialog .measure-level-dialog.dev-button.active { background-color: #247C40 }
.level-dialog .measure-level-dialog.dev-button.disabled { background-color: #ddd }


.log-history .MuiTableCell-head { color: white }
.log-history table td { border: 0; color: white}
.log-history .table-wrap{ width: 900px}


@media screen {
    .print-wrap { height: 0; overflow: hidden}
}

@media print{
    /*
        210 * 297(mm)
        72ppi는 595 * 842(px)
        300ppi는 2480 * 3508(px)
    */
    
    .content-print {
        width: 2480px;
        min-height: 3508px;
        position: relative;
        overflow: hidden;
    }

    .print-h1 { font-size: 50px}
    .print-h2 { font-size: 48px}
    .show-fft { display: none}

    .print-hide { display: none}
    
    .print-landscape {
        width: 3508px;
        min-height: 2480px;
        position: relative; 
        zoom: 10%
    }
    
}

.level-table tbody td {
  padding-top: 10px;
  padding-bottom: 11px;
}

.level-table-c td{
  padding: 12px 24px 12px 16px !important;
  font-size: 1.1rem;
}
.level-table-d td{
  padding: 8px 24px 10px 8px !important;
  font-size: 0.9rem;
}

.content-print {
    width: 2480px;
    min-height: 3508px;
    zoom: 47.93%;
    position: relative;
}

.print-logo {
    padding-left: 10px;
    margin-top: 30px;
    margin-bottom: 30px;
    display: none
}



.border-table { border: 1px solid #ddd; width: 100%; padding-left: 30px}
.border-table th { border-bottom: 1px solid #ddd; border-right: 1px solid #ddd}
.border-table th:last-child { border-bottom: 1px solid #ddd; border-right: 0}
.border-table { border: 1px solid #ddd}
.border-table td { border-bottom: 1px solid #ddd; border-right: 1px solid #ddd; text-align: center}
.border-table td:last-child { border-bottom: 1px solid #ddd; border-right: 0}


.content-print .inner-title { position: relative; padding-left: 48px}
.content-print .inner-title .ico { width: 34px; position: absolute; top: 14px; left:0 }

.print-user-title { font-size: 90px !important; font-weight: bold; text-align: center; margin-bottom: 150px; position: relative}
.print-user-title .subtitle { position: absolute; font-weight:normal; width: 100%; top: 120px; text-align: center;  font-size: 50px !important;}
.print-user-info-table {font-size:48px; height: 930px; width: 100%; color: #2648AC; font-size: 50px;  border: 3px solid #2648AC;}
.print-user-info-table th { background-color: #D6DDEF; border-right: 3px solid #2648AC; border-bottom: 3px solid #2648AC; text-align: center }
.print-user-info-table td { padding-left:20px; text-align: center; color: #000; border-bottom: 3px solid #2648AC;  border-right: 3px solid #2648AC;  }

.print-table { border: 3px solid #000; }
.print-table th { background-color: #D8E2FB; border-right: 3px solid #000; border-bottom: 3px solid #000; text-align: center }
.print-table td { border-bottom: 3px solid #000;  border-right: 3px solid #000; text-align: center; }
.print-table td.c-r-border { border-right: 0px !important;}
.print-table td.text-blue { color: #30468F}
.print-table td.text-danger { color: #ED2929}
.print-table td.text-success{ color: #009500}
.print-table .left-or-right {text-align: left;color: red;font-size: 30px;font-weight: bolder;margin-top: -50px;width: 200px;background: #fff;}


.memory-complex .cal-box {  background-color: #fff; border: 3px solid #ddd; height: 80px; width: 120px; text-align: center; line-height: 80px; font-weight: bold; font-size:3rem; color: #000; border-radius: 10px; display: inline-block; margin: 0px 20px}
.memory-complex .cal-text {
  text-shadow: 3px 3px 0 #FFFFFF,
  -3px -3px 0 #FFFFFF,
  3px -3px 0 #FFFFFF,
  -3px 3px 0 #FFFFFF;
}